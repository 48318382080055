import { Divider, Modal } from "antd";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/helper";

const ProfileModal = ({ profileModal = false, setProfileModal }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/editProfile");
    setProfileModal(false);
  };
  return (
    <div>
      <Modal
        centered
        open={profileModal}
        footer={null}
        onCancel={() => setProfileModal(false)}
      >
        <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg text-center mt-4">
          Profile Information
        </h3>
        <div className="flex flex-col justify-center items-center bg-white py-2">
          <Divider />
          <img src={ImageUrl("profile-5.png")} alt="profile" className="mt-4" />
          <h5 className="mt-2 mb-4 text-center font-euclid text-sm font-semibold text-customBlack">
            Darek James
          </h5>
          <Divider />
          <div className="flex justify-center items-center mt-3">
            <p className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 border-r border-gray-200">
              50+ <br />
              <span className="text-xs font-semibold">Rides</span>
            </p>
            <div className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 border-r border-gray-200 flex flex-col justify-center items-center">
              <div className="flex justify-center">
                <FaStar className="mr-1 text-customGold" /> 5.0
              </div>
              <span className="text-xs font-semibold">Rating</span>
            </div>
            <p className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 ">
              4.2 <br />
              <span className="text-xs font-semibold">Years</span>
            </p>
          </div>
          <button
            className="uppercase bg-button py-4 px-8 xl:px-16 text-white rounded-lg text-sm font-bold max-sm:text-xs mt-6"
            onClick={handleClick}
          >
            Edit Profile
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileModal;
