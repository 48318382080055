import { Col, message, Pagination, Row } from "antd";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";

const BlogComponent1 = () => {
  const CustomBox = ({ image, heading, text }) => {
    return (
      <Col md={12}>
        <div className="flex flex-col items-start">
          <img src={image} alt="blog" className="rounded-lg mb-8" />
          <h5 className="capitalize font-tavi text-black text-2xl mb-4">
            {heading}
          </h5>
          <p className="font-euclid text-sm">
            {text} <br />
            <Link
              to="/blogDetails"
              className="text-customGold hover:text-customGold"
            >
              READ MORE...
            </Link>
          </p>
        </div>
      </Col>
    );
  };

  const items = [
    {
      image: ImageUrl("blog-1.png"),
      heading: "reimagine the way the world moves",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-2.png"),
      heading: "Rides and beyond",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-3.png"),
      heading: "How to reduce the carbon footprint",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-4.png"),
      heading: "safety drives us",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-1.png"),
      heading: "reimagine the way the world moves",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-2.png"),
      heading: "Rides and beyond",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-3.png"),
      heading: "How to reduce the carbon footprint",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-4.png"),
      heading: "safety drives us",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
  ];
  return (
    <Row justify="center">
      <Col
      xxl={12}
        md={18}
        sm={18}
        xs={18}
        className="bg-white py-16 border-b border-gray-300"
      >
        <Row justify="center" gutter={[16, 48]}>
          {items.map((item) => (
            <CustomBox
              image={item.image}
              heading={item.heading}
              text={item.text}
            />
          ))}
        </Row>
        <Row justify="end" style={{ padding: "10px 20px" }}>
          {/* <Col xs={24} md={12}>
            <p>{message}</p>
          </Col> */}
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Pagination className="styledPagination" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BlogComponent1;
