import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";
import HomeComponent6 from "../../components/HomeComponents/HomeComponent6/HomeComponent6";
import { ImageUrl } from "../../config/helper";
import TeamsComponent1 from "./../../components/TeamsComponents/TeamsComponent1";
const Teams = () => {
  return (
    <div>
      <AboutComponent1 image={ImageUrl("contact-back.png")} heading="Teams" />
      <TeamsComponent1 heading={false}/>
      <HomeComponent4 />
      <HomeComponent6 />
    </div>
  );
};

export default Teams;
