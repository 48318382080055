import { Col, Row } from "antd";
import GoogleMapReact from "google-map-react";
import { ImLocation } from "react-icons/im";
import { ImageUrl } from "../../config/helper";
import RideComponentRecent from "../../components/RideComponents/RideComponentRecent";

const Marker = ({ image }) => <div>{image}</div>; // Custom marker component

const RecentPage = () => {
  const defaultProps = {
    center: {
      lat: 34.052235,
      lng: -118.243683,
    },
    zoom: 11,
  };

  return (
    <Row justify="center py-16">
      <Col md={22} xs={22}>
        <Row justify="center" gutter={[16, 16]}>
          <Col lg={6} xs={24}>
            <RideComponentRecent />
          </Col>
          <Col lg={18} xs={24} className="h-screen max-md:h-[550px]">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }} // Add your Google Maps API key here
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              {/* Marker for Main Street */}
              <Marker
                lat={34.052235}
                lng={-118.243683}
                image={<img src={ImageUrl("c-1.png")} alt="car" />}
              />
            </GoogleMapReact>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RecentPage;
