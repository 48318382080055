import { Divider, Modal } from "antd";
import { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { RiEdit2Line } from "react-icons/ri";
import AddPlaceModal from "./AddPlaceModal";

const ChoosePlaceModal = ({ choosePlaceModal, setChoosePlaceModal }) => {
  const [addPlaceModal, setAddPlaceModal] = useState(false);
  console.log(addPlaceModal);
  const items = [
    {
      name: "home",
      address: "1 Mckenzie, Blvd, Collage Station",
    },
    {
      name: "home",
      address: "1 Mckenzie, Blvd, Collage Station",
    },
  ];
  const CustomRow = ({ name, address }) => {
    return (
      <div className="flex flex-col items-start justify-center">
        <Divider />
        <div className="flex justify-between items-center w-full my-4">
          <div className="flex justify-center">
            <RiHomeSmile2Fill
              size={38}
              className="self-center mr-2 text-[#E28C06]"
            />
            <div className="flex flex-col items-start justify-center">
              <h5 className="uppercase font-euclid text-sm max-sm:text-xs font-bold text-customBlack">
                {name}
              </h5>
              <p className="font-euclid text-xs max-sm:text-[10px] text-customLightGray">
                {address}
              </p>
            </div>
          </div>
          <RiEdit2Line size={28} className="self-center text-customGray" />
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        centered
        open={choosePlaceModal}
        footer={null}
        onCancel={() => setChoosePlaceModal(false)}
      >
        <div className="flex flex-col w-full px-4 max-sm:px-2">
          <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg my-4">
            Choose a Place
          </h3>
          {items.map((item) => (
            <CustomRow name={item.name} address={item.address} />
          ))}
          <Divider />
          <button
            className="flex justify-start items-center my-4"
            onClick={() => setAddPlaceModal(true)}
          >
            <IoIosAddCircle
              size={36}
              className="text-customLightGray cursor-pointer"
            />
            <p className="font-euclid text-sm text-customBlack ml-2 font-bold max-sm:text-xs max-sm:ml-1">
              Add a new place
            </p>
          </button>
        </div>
      </Modal>
      {addPlaceModal && (
        <AddPlaceModal
          addPlaceModal={addPlaceModal}
          setAddPlaceModal={setAddPlaceModal}
        />
      )}
    </div>
  );
};

export default ChoosePlaceModal;
