import { Col, Row } from "antd";
import { ImageUrl } from "../../../config/helper";

const HomeComponent5 = () => {
  return (
    <Row
      justify="center"
      style={{
        backgroundImage: `url('${ImageUrl("back-1.png")}')`,
        ackgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className=""
    >
      <Col md={24}>
        <Row
          justify="center"
          className=""
          style={{ backgroundColor: "rgba(0, 0, 0, 0.80)" }}
        >
          <Col md={12} xs={22} className="py-64 max-sm:py-32">
            <div className="flex flex-col justify-center items-center">
              <h3 className="capitalize text-center font-tavi text-customGold text-6xl max-desktop:text-4xl">
                To the airport
                <br />
                <span className="text-white">with maximum comfort</span>
              </h3>
              <p className="text-center font-euclid text-white text-2xl my-8 max-desktop:text-lg">
                We value the time and quality of
                <br /> travel for each of our clients
              </p>
              <button className="uppercase bg-button py-4 px-8 text-white rounded-lg text-sm font-bold max-desktop:text-xs">
                AIRPORT TRANSFER
              </button>
            </div>
          </Col>
        </Row>

        <Row justify="center" className="bg-customGold">
          <Col xxl={18} xs={22}>
            <Row justify="center" gutter={[32, 0]}>
              <Col md={16} sm={18} xs={18} className="self-center">
                <div className="flex justify-center items-center">
                  <p className="capitalize text-white text-xl font-semibold max-desktop:text-lg max-lg:text-xs max-sm:text-[10px] py-4">
                    Unlock a world of exclusive benefits and unparalleled
                    convenience – join our membership programs today. get up to
                    15% discount on all rides, credited as cash back directly
                    into your wallet for future use!
                  </p>
                </div>
              </Col>
              <Col md={8} sm={6} xs={6} className="self-center">
                <img
                  src={ImageUrl("card.png")}
                  alt="card"
                  className="md:my-[-35px]"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeComponent5;
