import { Col, Row } from "antd";
import { ImageUrl } from "../../../config/helper";

const HomeComponent2 = () => {
  return (
    <Row justify="center" className="bg-customBlack py-24 max-2xl:py-4">
      <Col md={20}>
        <Row justify="center">
          <Col lg={10} md={20} sm={20} xs={20} className="max-xl:self-center">
            <div className="flex max-lg:item-center">
              <img
                src={ImageUrl("back-2.png")}
                alt="back-2"
                className="xl:mb-[-200px]"
              />
            </div>
          </Col>
          <Col lg={14} md={24} sm={20} xs={20}>
            <div className="flex flex-col justify-center items-start md:ml-8">
              <h3 className="capitalize text-2xl font-tavi text-white mt-8 max-md:text-lg max-desktop:text-xl">
                <span className="text-customGold text-4xl max-md:text-2xl max-desktop:text-3xl">
                  Discover The Difference!
                </span>{" "}
                - Where Excellence Is Not Just A Goal, But A Promise.
              </h3>
              <p className="font-euclid text-lg text-white my-8 max-desktop:text-sm">
                Discover the epitome of luxury transportation with Badshah Limo.
                Our premium service goes beyond mere travel – it's an experience
                tailored to your every need. From opulent rides to impeccable
                safety standards, personalized service, and transparent pricing,
                we redefine what it means to journey in style.
              </p>

              <Row justify="left" className="w-full">
                <Col xxl={20} md={24} xs={24}>
                  <p className="font-euclid text-lg text-white mb-8 max-desktop:text-sm font-bold text-center w-full uppercase">
                    Welcome aboard the Badshah Limo experience.
                  </p>
                  <Row
                    justify="center"
                    gutter={[32, 32]}
                    className="mb-8 w-full"
                  >
                    <Col lg={6} md={12} sm={12} xs={12} className="w-full">
                      <div className="flex flex-col justify-center items-center border border-customGold h-[130px] px-4 iconCard">
                        <img
                          src={ImageUrl("v1.png")}
                          alt="vector"
                          className="mb-2 max-h-[90px]"
                        />
                        <p className="font-euclid text-sm max-xl:text-[10px] text-center uppercase text-white">
                          PREMIUM
                          <br /> RIDES
                        </p>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="flex flex-col justify-center items-center border border-customGold h-[130px] px-4 iconCard">
                        <img
                          src={ImageUrl("v2.png")}
                          alt="vectorImage"
                          className="mb-2"
                        />
                        <p className="font-euclid text-sm max-xl:text-[10px] text-center uppercase text-white">
                          PRIORITY
                          <br /> SUPPORT 24/7
                        </p>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="flex flex-col justify-center items-center border border-customGold h-[130px] px-4 iconCard">
                        <img
                          src={ImageUrl("v3.png")}
                          alt="vector"
                          className="mb-2 "
                        />
                        <p className="font-euclid text-sm max-xl:text-[10px] text-center uppercase text-white">
                          PROMPT
                          <br /> PROTECTION
                        </p>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="flex flex-col justify-center items-center border border-customGold h-[130px] px-4 iconCard">
                        <img
                          src={ImageUrl("v4.png")}
                          alt="vector"
                          className="mb-2"
                        />
                        <p className="font-euclid text-sm max-xl:text-[10px] text-center uppercase text-white">
                          PROFESSIONAL
                          <br /> Chauffeurs
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="flex justify-center max-lg:w-full">
                <button className="uppercase bg-button py-4 px-14 text-white rounded-lg text-sm font-bold text-center">
                  BOOK NOW!
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeComponent2;
