import { Divider, Input, Modal, Radio, Form, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/helper";
import { BsCalendar2DateFill } from "react-icons/bs";
import { Option } from "antd/es/mentions";
import { MdOutlinePayment } from "react-icons/md";

const GiftModal = ({ giftModal, setGiftModal }) => {
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const handleClick = () => {
    setGiftModal(false);
  };
  const packages = [
    {
      index: 0,
      image: "travel.png",
      text: "travel",
    },
    {
      index: 1,
      image: "holiday.png",
      text: "holiday",
    },
    {
      index: 2,
      image: "nightout.png",
      text: "nightout",
    },
  ];
  const amounts = [
    {
      index: 0,
      text: "$25",
    },
    {
      index: 1,
      text: "$50",
    },
    {
      index: 2,
      text: "$75",
    },
    {
      index: 4,
      text: "$100",
    },
  ];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {};
  const CustomAmount = ({ index, text }) => {
    return (
      <p
        className={`py-2 px-6 max-sm:px-2 text-sm font-euclid font-bold rounded-lg cursor-pointer max-sm:text-[12px] ${
          index === selectedAmount ? "bg-white" : ""
        }`}
        onClick={() => setSelectedAmount(index)}
      >
        {text}
      </p>
    );
  };
  const CustomPackages = ({ image, text, index }) => {
    return (
      <div
        className={`flex flex-col items-center justify-center cursor-pointer ${
          index === 1 ? "mx-2" : ""
        }`}
        onClick={() => setSelectedPackage(index)}
      >
        <img src={ImageUrl(image)} alt="travel" />
        <p className="uppercase font-euclid text-xs text-center text-customBlack font-bold mt-1">
          {text}
        </p>
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={giftModal}
        footer={null}
        onCancel={() => setGiftModal(false)}
      >
        <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg text-center my-4">
          Buy A Gift Card
        </h3>
        <Divider />
        <div className="flex flex-col justify-center items-center bg-white my-4 px-8 max-sm:px-2">
          <h5 className="font-tavi text-lg max-sm:text-xs text-black ml-4 text-start w-full mb-2">
            Choose Image
          </h5>
          <img
            src={ImageUrl(packages[selectedPackage].image)}
            alt="gift-select"
            className="h-[230px] w-[450px] max-sm:h-[150px]"
          />
          <div className="flex justify-center items-center mt-3 mb-6">
            {packages.map((item) => (
              <CustomPackages
                image={item.image}
                text={item.text}
                index={item.index}
                key={item.index}
              />
            ))}
          </div>
          <div className="flex flex-col justify-center items-center w-full sm:px-4">
            <p className="ml-4 mb-2 text-black text-sm font-euclid font-bold text-start w-full">
              Select Amount
            </p>
            <div className="border border-gray-200 rounded-lg p-2 bg-gray-100 w-full flex justify-between items-center">
              {amounts.map((item) => (
                <CustomAmount
                  key={item.index}
                  text={item.text}
                  index={item.index}
                />
              ))}
            </div>
            <p className="text-xs text-[#0A94A7] text-end w-full font-euclid font-bold mt-1 mb-4">
              Set Custom Amount
            </p>
            <p className="ml-4 mb-2 text-black text-sm font-euclid font-bold text-start w-full">
              Delivery
            </p>
            <Radio.Group className="w-full mb-2">
              <div className="flex justify-around items-center w-full">
                <Radio
                  value="text"
                  className="text-sm max-sm:text-[10px] font-bold text-customGray"
                >
                  Text Message
                </Radio>
                <Radio
                  value="email"
                  className="text-sm max-sm:text-[10px] font-bold text-customGray"
                >
                  Email Address
                </Radio>
              </div>
            </Radio.Group>
            <Input
              placeholder="Recipent Phone Number"
              className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs mb-4"
              autoComplete={false}
              type="number"
            />
            <p className="ml-4 mb-2 text-black text-sm font-euclid font-bold text-start w-full">
              Choose Recipent
            </p>
            <Form
              form={form}
              name="normal_login"
              onFinish={onFinish}
              layout="vertical"
              className="w-full"
            >
              <Form.Item
                name="name"
                label={
                  <p className="text-customGray text-sm max-sm:text-xs font-euclid ml-4 font-bold">
                    Recipent
                  </p>
                }
              >
                <Input
                  placeholder="Recipent Name"
                  className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                  autoComplete={false}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="message"
                label={
                  <p className="text-customGray text-sm max-sm:text-xs font-euclid ml-4 font-bold">
                    Add Message
                  </p>
                }
                className="mt-[-6px]"
              >
                <TextArea
                  placeholder="Enjoy a BLR ride from me!"
                  className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                  autoComplete={false}
                  type="text"
                  rows={4}
                />
              </Form.Item>
            </Form>
            <button
              className="uppercase bg-[#F7F6FF] py-4 px-16 text-black rounded-lg text-sm font-bold max-sm:text-xs max-md:py-2 max-md:px-4 font-euclid mb-4"
              onClick={handleClick}
            >
              preview
            </button>
            <p className="ml-4 mb-2 text-black text-sm font-euclid font-bold text-start w-full">
              Choose Delivery Date
            </p>
            <p className="ml-4 mb-4 text-customGray text-sm font-euclid font-bold text-start w-full flex">
              <BsCalendar2DateFill size={24} className="mr-2" /> Friday,
              September 04
            </p>
            <button
              className="uppercase bg-[#F7F6FF] py-4 px-16 text-black rounded-lg text-sm font-bold max-sm:text-xs max-md:py-2 max-md:px-4 font-euclid mb-4"
              onClick={handleClick}
            >
              change date
            </button>
            <p className="ml-4 mb-2 text-black text-sm font-euclid font-bold text-start w-full">
              Purchase Review
            </p>
            <div className="relative w-full selection mb-4">
              <MdOutlinePayment
                className="absolute top-4 left-2 z-10 text-[#4E4D60]"
                size={20}
              />
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={
                  <p className="px-6 capitalize  max-sm:text-xs">
                    Choose a payment method
                  </p>
                }
                className="font-euclid text-xs text-customGray h-[50px]"
              >
                <Option className="custom-option" value="Option1">
                  Option
                </Option>
                <Option className="custom-option" value="Option2">
                  Option 2
                </Option>
                <Option className="custom-option" value="Option3">
                  Option 3
                </Option>
              </Select>
            </div>
          </div>
          <button
            className="uppercase bg-button py-4 px-16 text-white rounded-lg text-sm font-bold max-sm:text-xs mt-6 font-euclid"
            onClick={handleClick}
          >
            buy now!
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GiftModal;
