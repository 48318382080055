import { Col, Row } from "antd";
import React from "react";
import { ImageUrl } from "../../config/helper";

const BlogComponent2 = () => {
  return (
    <Row justify="center" className="bg-white py-16 max-sm:py-8">
      <Col md={18} xs={22}>
        <div className="flex flex-col items-start">
          <img
            src={ImageUrl("blogDetails-1.png")}
            alt="details"
            className="rounded-lg"
          />
          <h5 className="capitalize font-tavi text-3xl text-black my-8 max-sm:my-6 max-sm:text-lg">
            safety drives us
          </h5>
          <p className="font-euclid text-sm text-black leading-loose">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the with the
            release of Letraset sheets containing Lorem Ipsum passages, and more
            recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum. <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
            Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
            vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget
            odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
            Cum sociis natoque. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
            luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
            sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
            Proin gravida dolor sit amet lacus accumsan et viverra justo
            commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus
            et magnis dis parturient montes, nascetur ridiculus mus. Nam
            fermentum, nulla luctus pharetra vulputate, felis tellus mollis
            orci, sed rhoncus sapien nunc eget odio. <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
            Cum sociis natoque. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Nam fermentum, nulla
            luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus
            sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
            dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default BlogComponent2;
