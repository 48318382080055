import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import BlogComponent1 from "../../components/BlogComponents/BlogComponent1";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";

const Blogs = () => {
  return (
    <div>
      <AboutComponent1 image="./assets/images/blog-back.png" heading="Blog" />
      <BlogComponent1 />
      <HomeComponent4 />
    </div>
  );
};

export default Blogs;
