import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Layout/Header";
import { ImageUrl } from "../../config/helper";

const SignIn = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data = {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };

      Swal.fire({
        icon: "success",
        title: "Login Successful!",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/ride");
      }, 1500);

      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <Row
        justify="center"
        style={{
          backgroundImage: `url('${ImageUrl("register-back.png")}')`, // Setting background color to black with 90% opacity
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="flex items-center justify-center min-h-screen"
      >
        <Col lg={8} sm={20} xs={20} className="flex flex-col justify-center my-52 max-sm:my-32">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg py-8 px-8 max-lg:px-2 ">
            <h3 className="mt-4 font-tavi text-3xl text-black max-lg:text-2xl">
              Sign In
            </h3>
            <p className="capitalize font-euclid text-sm text-[#34324B] mb-8 max-lg:text-xs text-center">
              Enter your credentials to Sign in to the platform
            </p>
            <Form
              form={form}
              name="normal_login"
              onFinish={onFinish}
              layout="vertical"
              className="w-full px-8 max-xl:px-2"
            >
              <Row justify="center" gutter={[16, 16]}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    name="email"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Email Address
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                      autoComplete={false}
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    name="password"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Password
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      className="h-12 text-sm font-euclid bg-gray-100 "
                      autoComplete={false}
                    />
                  </Form.Item>
                  <div className="flex justify-between md:mx-4">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className="flex items-center"
                    >
                      <Checkbox>
                        <p className="text-sm text-black font-euclid max-xl:text-xs">
                          Remember me
                        </p>
                      </Checkbox>
                    </Form.Item>
                    <Link
                      to="/forgotPassword"
                      className="text-sm font-semiboldfont-euclid text-[#0B7FC3] hover:text-[#0B7FC3] pt-2 max-xl:text-xs"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </Col>
              </Row>

              <Row justify="center">
                <Col md={12}>
                  <Form.Item className="self-center text-center">
                    <Button
                      htmlType="submit"
                      className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-14 h-[50px] hover:text-customGold text-center text-sm"
                    >
                      {loading ? "LOADING..." : "Login"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Link
                  to="/signIn"
                  className="font-euclid text-black text-sm  font-normal mt-4 hover:text-black max-xl:text-xs max-sm:text-[10px]"
                >
                  Don’t Have An Account?{" "}
                  <Link
                    to="/register"
                    className="font-semibold  border-b-2 border-black"
                  >
                    SIGN UP
                  </Link>
                </Link>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SignIn;
