import Header from "../../Layout/Header";
import { Col, Rate, Row } from "antd";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CustomCard from "./CustomCard";
import { ImageUrl } from "../../../config/helper";
import { useNavigate } from "react-router-dom";

const HomeComponent1 = () => {
  // const items = [
  //   <img src={ImageUrl("front-1.png")} />,
  //   <img src={ImageUrl("front-2.png")} />,
  //   <img src={ImageUrl("front-3.png")} />,
  // ];

  const CustomBox = ({ heading1, heading2, heading3, heading4, image }) => (
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start capitalize">
            <h5 className="font-euclid text-2xl text-white mb-8 max-xl:mb-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px]">
              {heading1} <br />
              <span className="text-customGold">{heading2}</span> {heading3}
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              {heading4}
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl(image)}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>
  );

  const items = [
    <CustomBox
      heading1={"Punctual Luxury"}
      heading2={"Stylish"}
      heading3={"Transits"}
      heading4={"for your Success in Business"}
      image={"front-1.png"}
    />,
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start capitalize">
            <h5 className="font-euclid text-2xl text-white mb-4 max-xl:mb-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px]">
              Everything is <span className="text-customGold">Premium</span>
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              Chauffeurs, Cars, Clients
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl("front-2.png")}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>,
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start">
            <h5 className="font-euclid text-2xl text-white mb-4 max-xl:mb-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px] capitalize">
              Experience the <span className="text-customGold">royal</span>{" "}
              <br />
              treatment
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              where you're not just a passenger,
              <br /> but a cherished guest."
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl("front-3.png")}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>,
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start">
            <h5 className="font-euclid text-2xl text-white mb-4 max-xl:mb-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px] capitalize">
              Your <span className="text-customGold">comfort</span> and{" "}
              <span className="text-customGold">Satisfaction</span>
              <br /> are paramount
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              because feeling valued is not just a luxury,
              <br /> it's our standard
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl("front-1.png")}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>,
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start">
            <h5 className="font-euclid text-2xl text-white mb-6 max-xl:mb-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px] capitalize">
              Prepare to be <span className="text-customGold">impressed</span>
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              where our commitment to excellence ensures an <br />
              experience that exceeds your wildest expectations
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl("front-2.png")}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>,
    <>
      <Row
        justify="center"
        className="absolute z-10 top-1/3 max-lg:top-1/3 ml-40 max-2xl:ml-32 max-lg:ml-32 max-sm:top-[40%] max-sm:ml-8"
      >
        <Col md={24}>
          <div className="flex flex-col justify-center items-start">
            <h5 className="font-euclid text-2xl text-white mb-6 max-xl:my-2 max-xl:text-lg max-md:text-sm  capitalize font-bold">
              At Badshah Limo / BLUS
            </h5>
            <h3 className="font-tavi text-[52px] text-white max-xl:text-3xl max-md:text-xl lg:leading-[40px] capitalize">
              Expect nothing less than
              <br /> <span className="text-customGold">perfection</span>
            </h3>
            <h5 className="font-euclid text-xl text-white mb-8 max-xl:my-4 max-xl:text-lg max-md:text-sm capitalize">
              where every detail is meticulously crafted to <br /> ensure your
              satisfaction
            </h5>
            <button
              className="uppercase bg-button xl:py-4 py-3 px-6 xl:px-16 text-white rounded-lg text-lg font-bold max-xl:text-sm max-md:text-xs"
              onClick={() => navigate("/ride")}
            >
              book now
            </button>
          </div>
        </Col>
      </Row>
      <img
        src={ImageUrl("front-3.png")}
        className="max-md:h-[500px] object-cover w-full overflow-hidden"
      />
    </>,
  ];

  const navigate = useNavigate();
  return (
    <div className="relative banner customCaraousel">
      <Header />
      <AliceCarousel
        disableButtonsControls
        // autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={3000}
        animationDuration={2000}
        animationType="slide"
        infinite
        touchTracking={false}
        // disableDotsControls
        items={items}
        className=" custom-carousel"
      />
      {/* <CustomCard /> */}
    </div>
  );
};

export default HomeComponent1;
