import { Col, Row } from "antd";
import { ImageUrl } from "../../../config/helper";

const HomeComponent3 = () => {
  return (
    <Row
      justify="center"
      style={{
        backgroundImage: `url('${ImageUrl('back-3.png')}')`, // Setting background color to black with 90% opacity
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="md:h-py-64 py-24 bg-no-repeat"
    >
      <Col md={22} sm={22} xs={22}>
        <Row justify="center">
          <Col md={12} sm={12} xs={12}>
            <div className="flex flex-col justify-center items-center">
              <p className="capitalize font-euclid text-black text-xl mb-4 text-center max-md:text-lg mt-8">
                We value the time and quality of travel for each of our clients
              </p>
              <h3 className=" font-tavi text-6xl text-customGold text-center max-md:text-4xl">
                Incredible
                <br />
                <span className="text-5xl text-black max-md:text-3xl">
                  Comfort
                </span>
              </h3>
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <div
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={ImageUrl("car.png")} alt="car" className="car" />
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeComponent3;
