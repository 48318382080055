import { Button, Col, Form, Input, message, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useState } from "react";
import { ImageUrl } from "../../config/helper";

const ContactComponent1 = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data = {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" className="py-16 max-sm:py-8">
      <Col xl={18} xs={22} className="border border-gray-300 p-12 max-sm:p-6">
        <Row justify="center">
          <Col lg={14} xs={24} className="bg-white lg:py-12 md:pr-16">
            <h4 className="font-tavi text-customBlack text-3xl mb-4">
              GET IN TOUCH
            </h4>
            <Form
              form={form}
              name="normal_login"
              onFinish={onFinish}
              layout="vertical"
            >
              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    name="firstName"
                    label={
                      <p className="font-euclid text-sm text-black font-bold">
                        Full Name
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Name"
                      className="h-12 text-sm font-euclid bg-gray-100"
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    name="lastName"
                    label={
                      <p className="font-euclid text-sm text-black font-bold">
                        Last Name
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      className="h-12 text-sm font-euclid bg-gray-100"
                      autoComplete={false}
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    name="email"
                    label={
                      <p className="font-euclid text-sm text-black font-bold">
                        Email Address
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      className="h-12 text-sm font-euclid bg-gray-100"
                      autoComplete={false}
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    name="subject"
                    label={
                      <p className="font-euclid text-sm text-black font-bold">
                        Subject
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input subject!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Subject"
                      className="h-12 text-sm font-euclid bg-gray-100"
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    name="message"
                    label={
                      <p className="font-euclid text-sm text-black font-bold">
                        Message
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your message!",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Message"
                      rows={5}
                      className="text-lg font-euclid bg-gray-100"
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 8]}>
                <Col md={8}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-10 h-[50px] hover:text-customBrown text-center text-sm"
                    >
                      {loading ? "LOADING..." : "SEND FEEDBACK"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col
            lg={10}
            xs={24}
            className="p-16 max-desktop:p-6 rounded-lg "
            style={{
              backgroundImage: `url('${ImageUrl("contact-bg.png")}')`, // Setting background color to black with 90% opacity
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="flex flex-col h-full justify-between">
              <Row>
                <div className="flex flex-col justify-center ">
                  <h4 className="font-tavi desktop:text-3xl text-2xl md:mb-8 mb-6 text-white max-xl:text-lg max-sm:text-sm">
                    CONTACT INFORMATION
                  </h4>
                  {/* <div className="flex justify-start items-center mb-12">
                  <FaMapLocationDot className="text-white mr-8 min-h-5 min-w-5 lg:min-h-10 lg:min-w-10" />
                  <p className="font-euclid text-white text-lg max-xl:text-sm max-sm:text-xs">
                    ABC road, 123 street New York
                  </p>
                </div> */}
                  <div className="flex justify-start items-center md:mb-6 mb-4">
                    <FaPhoneSquareAlt className="text-white mr-8 max-desktop:mr-2  min-h-5 min-w-5 lg:min-h-10 lg:min-w-10" />
                    <p className="font-euclid text-white text-lg max-desktop:text-sm max-sm:text-xs">
                      (272) 223-7424 |{" "}
                      <span className="text-customGold">AS-A-BADSHAH</span>
                    </p>
                  </div>
                  <div className="flex justify-start items-center">
                    <IoIosMail className="text-white mr-8 max-desktop:mr-2  min-h-5 min-w-5 lg:min-h-11 lg:min-w-11" />
                    <p className="font-euclid text-white text-lg max-desktop:text-sm max-sm:text-xs">
                      support@badshah.limo
                    </p>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="flex flex-col items-start mt-8">
                  <h5 className="font-tavi desktop:text-3xl text-2xl max-xl:text-lg text-white mb-3 2xl:mb-5">
                    Social Media
                  </h5>
                  <div className="flex justify-start items-center">
                    <a href="https://www.facebook.com/badshah.limo">
                      <img
                        src={ImageUrl("facebook.png")}
                        alt="facebook"
                        className="max-xl:h-[30px]"
                      />
                    </a>
                    <a href="https://www.instagram.com/badshah.limo/">
                      <img
                        src={ImageUrl("insta.png")}
                        alt="insta"
                        className="max-xl:h-[30px] mx-4"
                      />
                    </a>
                    <a href="https://www.threads.net/@badshah.limo?xmt=AQGz4yUMoPlpr4jlOBfAK6Dw6yF22sLhTd2r89TLTEwQcVY">
                      <img
                        src={ImageUrl("twitter.png")}
                        alt="twitter"
                        className="max-xl:h-[30px]"
                      />
                    </a>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactComponent1;
