import { Divider, Form, Input, Modal } from "antd";
import { useState } from "react";

const AddPlaceModal = ({ addPlaceModal = false, setAddPlaceModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {};
  const handleClick = () => {
    setAddPlaceModal(false);
  };
  return (
    <div>
      <Modal
        centered
        open={addPlaceModal}
        footer={null}
        onCancel={() => setAddPlaceModal(false)}
      >
        <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg text-center my-4">
          Add Place
        </h3>
        <Divider />
        <div className="flex flex-col justify-center items-center bg-white my-4 px-8">
          <Form
            form={form}
            name="normal_login"
            onFinish={onFinish}
            layout="vertical"
            className="w-full"
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input name!",
                },
              ]}
            >
              <Input
                placeholder="Enter Name"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please input address!",
                },
              ]}
            >
              <Input
                placeholder="Enter Address"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
          </Form>
          <button
            className="uppercase bg-button py-4 w-full text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:py-2 max-md:px-4 mt-6"
            onClick={handleClick}
          >
            save place
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddPlaceModal;
