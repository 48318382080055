import { Col, Rate, Row } from "antd";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { ImageUrl } from "../../../config/helper";

const HomeComponent6 = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1200: { items: 3 },
    1500: { items: 4 },
  };

  const CustomBox = ({ text, image, name, rating, position }) => {
    return (
      <div className="flex flex-col justify-center items-center border border-gray-200 bg-gray-100 p-8 mr-8 h-[400px] max-md:h-[290px] max-md:p-4">
        <p
          className="font-euclid text-sm text-center max-md:text-xs max-2xl:text-sm font-[600] text-[#192026]"
          style={{ lineHeight: "30px" }}
        >
          {text}
        </p>
        <Rate disabled defaultValue={rating} className="my-6 max-md:my-2" />
        <img src={image} alt="profile" />
        <h5 className="my-2 font-euclid text-lg text-black max-md:text-sm">
          {name}
        </h5>
        <p className="font-euclid text-sm text-gray-500 max-md:text-xs">
          {position}
        </p>
      </div>
    );
  };

  const items = [
    {
      text: "“The customer support is amazing. They helped me with a problem I was having and were very responsive and friendly.”",
      rating: 5,
      image: ImageUrl("profile-2.png"),
      name: "Jane Smith",
      position: "Marketing Manager",
    },
    {
      text: "“I'm really happy with the speed and reliability of this web hosting service. My website loads quickly and I haven't experienced any downtime.”",
      rating: 4,
      image: ImageUrl("profile-3.png"),
      name: "Bob Johnson",
      position: "Software Developer",
    },
    {
      text: "Impressions matter, and at Badshah Limo, we strive to leave you feeling not just satisfied, but truly impressed and amazed. But don’t just... ",
      image: ImageUrl("profile-1.png"),
      name: "Tom Williams",
      position: "Freelance Designer",
    },
    {
      text: "“I was a bit hesitant to switch to a new web hosting service, but I'm glad I did. The control panel is easy to use and the features are great.”",
      rating: 4,
      image: ImageUrl("profile-4.png"),
      name: "Sarah Lee",
      position: "Product Manager",
    },
  ];

  return (
    <Row justify="center" className="py-24 max-md:py-8 customCaraousel2">
      <Col md={20} sm={20} xs={20}>
        <div className="flex flex-col justify-center items-start">
          <h3 className="font-tavi text-6xl text-black max-2xl:text-4xl">
            Client <span className="text-customGold">Testimonials</span>
          </h3>
          <p className="font-euclid text-lg text-black my-8 max-md:text-sm">
            Impressions matter, and at Badshah Limo, we strive to leave you
            feeling not just satisfied, but truly impressed and amazed.
            <br /> But don't just take our word for it - see what actual users
            of our service have to say about their experience.
          </p>
          <AliceCarousel responsive={responsive} disableButtonsControls>
            {items.map((item) => (
              <CustomBox
                key={item.text}
                text={item.text}
                rating={item.rating}
                image={item.image}
                name={item.name}
                position={item.position}
              />
            ))}
          </AliceCarousel>
        </div>
      </Col>
    </Row>
  );
};
export default HomeComponent6;
