import { Col, Row } from "antd";
import React from "react";
import { ImageUrl } from "../../../config/helper";

const CustomCard = () => {
  return (
    <Row
      justify="center"
      className="py-8 max-lg:py-2 bottom-0 z-10 w-full homeCard absolute max-lg:static"
    >
      <Col lg={20}>
        <Row justify="center" className="lg:h-full">
          <Col lg={8} md={22} sm={22}>
            <div className="flex justify-start items-center py-2 border-r border-white border-dashed max-lg:border-none px-8 lg:h-full">
              <img src={ImageUrl("service-1.png")} alt="reason" />
              <div className="flex flex-col items-start ml-4 ">
                <h5 className="font-tavi text-xl text-customGold mb-2 max-xl:text-sm">
                  Everything is Premium
                </h5>
                <p className="font-euclid text-sm text-gray-500 max-xl:text-[10px]">
                  Chauffeurs, Cars, Clients
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={22} sm={22}>
            <div className="flex justify-start items-center py-2 border-r border-white border-dashed max-lg:border-none px-8">
              <img src={ImageUrl("service-2.png")} alt="reason" />
              <div className="flex flex-col items-start ml-4 ">
                <h5 className="font-tavi text-xl text-customGold mb-2 max-xl:text-sm">
                  Embark on a journey of luxury and prestige
                </h5>
                <p className="font-euclid text-sm text-gray-500 max-xl:text-[10px]">
                  Where every ride is a testament to our dedication to
                  excellence.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={22} sm={22}>
            <div className="flex justify-start items-center py-2 px-8">
              <img src={ImageUrl("service-3.png")} alt="reason" />
              <div className="flex flex-col items-start ml-4 ">
                <h5 className="font-tavi text-xl text-customGold mb-2 max-xl:text-sm">
                  Experience the royal treatment
                </h5>
                <p className="font-euclid text-sm text-gray-500 max-xl:text-[10px]">
                  Where you're not just a passenger, but a cherished guest."
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CustomCard;
