import { Col, Input, Row } from "antd";
import { FaPhoneAlt, FaRegClock } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa6";
import { ImageUrl } from "../../config/helper";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <Row justify="center" className="bg-customBlack py-16">
      <Col md={24}>
        <Row
          justify="center"
          style={{
            backgroundImage: `url('${ImageUrl("footer-back.png")}')`, // Setting background color to black with 90% opacity
          }}
        >
          <Col md={20} sm={20} xs={20}>
            <Row justify="center" className="pb-16">
              <Col lg={12} md={24} sm={24}>
                <div className="flex flex-col">
                  <p className="uppercase text-white font-euclid text-lg max-desktop:text-sm">
                    Want to know about our offer first?
                  </p>
                  <h3 className="text-6xl text-white font-tavi mt-1 max-desktop:text-4xl max-md:text-2xl">
                    Subscribe Our{" "}
                    <span className="text-customGold">Newsletter!</span>
                  </h3>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} className="lg:pl-16 self-center">
                <div className="flex justify-between items-center bg-white py-2 max-desktop:py-2 max-desktop:px-1 px-2 rounded-lg max-lg:mt-4">
                  <Input
                    placeholder="Enter Your Email Address..."
                    className="bg-white pl-4 text-lg max-md:text-sm w-full mr-8 text-decoration-none border-white hover:border-white active:border-white font-euclid"
                  />
                  <button className="uppercase bg-button py-4 px-8 text-white rounded-lg text-xs max-md:text-[9px] max-md:px-4 max-md:py-2">
                    Subscribe
                  </button>
                </div>
              </Col>
            </Row>
            <Row justify="center" className="border-t border-b border-gray-500">
              <Col md={24} sm={24} xs={24}>
                <Row justify="center">
                  <img
                    src={ImageUrl("footer-1.png")}
                    alt="footer-image"
                    className="mb-8 max-lg:h-[150px] cursor-pointer"
                    onClick={() => navigate("/")}
                  />
                </Row>
                <Row justify="center">
                  <Col md={8} xs={12} className="md:border-r border-gray-500">
                    <div className="flex flex-col justify-center items-center md:px-16 py-8">
                      <div className="p-2 rounded-full bg-customGold mb-2">
                        <FaPhoneAlt size={24} />
                      </div>
                      <p className="text-lg font-euclid text-center text-white my-2 max-desktop:text-sm">
                        (272) 223-7424
                      </p>
                      <p className="text-lg font-euclid text-center text-customGold max-desktop:text-sm">
                        AS-A-BADSHAH
                      </p>
                    </div>
                  </Col>
                  <Col md={8} xs={12} className="md:border-r border-gray-500">
                    <div className="flex flex-col justify-center items-center md:px-16 py-8">
                      <div className="p-2 rounded-full bg-customGold mb-2">
                        <FaRegEnvelope size={24} />
                      </div>
                      <p className="text-lg font-euclid text-center text-white my-2 max-desktop:text-sm ">
                        support@badshah.limo
                      </p>
                      <p className="text-lg font-euclid text-center text-customGold max-desktop:text-sm ">
                        Contact Email
                      </p>
                    </div>
                  </Col>
                  <Col md={8} xs={12}>
                    <div className="flex max-lg:flex-col max-md:items-center justify-center items-start py-8 px-4 w-full">
                      <div className="flex justify-center items-center w-full">
                        <a
                          className="flex justify-center items-center p-2 bg-button rounded-full"
                          href="https://www.facebook.com/badshah.limo"
                        >
                          <FaFacebookF size={24} className="text-black" />
                        </a>
                        <a
                          className="flex justify-center items-center p-2 bg-button rounded-full mx-1"
                          href="https://www.instagram.com/badshah.limo/"
                        >
                          <FaInstagram size={24} className="text-black" />
                        </a>
                        <a
                          className="flex justify-center items-center p-2 bg-button rounded-full"
                          href="https://www.threads.net/@badshah.limo?xmt=AQGz4yUMoPlpr4jlOBfAK6Dw6yF22sLhTd2r89TLTEwQcVY"
                        >
                          <FaXTwitter size={24} className="text-black" />
                        </a>
                      </div>
                      <div className="w-full flex justify-center max-lg:mt-4">
                        <button
                          className="uppercase bg-button p-3 text-white rounded-lg text-sm font-bold max-2xl:text-xs flex flex-col items-center justify-center"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          <img src={ImageUrl("carIcon.png")} alt="icon" />
                          <p>TO TOP</p>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
