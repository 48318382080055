import HomeComponent1 from "../../components/HomeComponents/HomeComponent1/HomeComponent1";
import HomeComponent2 from "../../components/HomeComponents/HomeComponent2/HomeComponent2";
import HomeComponent3 from "../../components/HomeComponents/HomeComponent3/HomeComponent3";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";
import HomeComponent5 from "../../components/HomeComponents/HomeComponent5/HomeComponent5";
import HomeComponent6 from "../../components/HomeComponents/HomeComponent6/HomeComponent6";
import HomeComponent7 from "../../components/HomeComponents/HomeComponent7/HomeComponent7";

const Homepage = () => {
  return (
    <div>
      <HomeComponent1 />
      <HomeComponent4 />
      <HomeComponent5 />
      <HomeComponent6 />
      <HomeComponent2 />
      <HomeComponent7 />
      <HomeComponent3 />
    </div>
  );
};

export default Homepage;
