import { Col, Drawer, Dropdown, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { ImageUrl } from "../../config/helper";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Extract the pathname from the location object
    const currentPath = location.pathname;

    // Set the activeTab state based on the current path
    if (currentPath === "/") {
      setActiveTab("HOME");
    } else if (currentPath === "/viewRide") {
      setActiveTab("RIDE");
    } else if (currentPath === "/about") {
      setActiveTab("ABOUT");
    } else if (currentPath === "/contact") {
      setActiveTab("CONTACTS");
    } else {
      setActiveTab(""); // If none of the above, reset activeTab
    }
  }, [location.pathname]);
  const items = [
    {
      key: "1",
      label: (
        <Link to="/about" className="px-8 font-euclid text-sm font-semibold">
          ABOUT US
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/team" className="px-8 font-euclid text-sm font-semibold">
          OUR TEAM
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/blogs" className="px-8 font-euclid text-sm font-semibold">
          BLOG
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/register" className="px-8 font-euclid text-sm font-semibold">
          PR
        </Link>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Row
      justify="center"
      className="bg-none absolute z-10 w-full"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
    >
      <Col lg={20} md={22} sm={22} xs={22}>
        <Row justify="center">
          <Col md={4} sm={4} xs={4} className="self-center">
            <img
              src={ImageUrl("logo.png")}
              alt="logo"
              className="cursor-pointer"
              onClick={() => navigate("/")}
            />
          </Col>
          <Col md={0} sm={18} xs={18}></Col>
          <Col md={12} sm={0} xs={0} className="self-center">
            <Row justify="center" className="py-8">
              <div className="flex justify-center items-center">
                <Link
                  to="/"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "HOME" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center px-8 max-desktop:px-6 max-lg:px-4`}
                >
                  HOME
                </Link>
                <Link
                  to="/viewRide"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "RIDE" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center px-8 max-desktop:px-6 max-lg:px-4`}
                >
                  RIDE
                </Link>
                <Link
                  to="/signIn"
                  className="text-lg font-euclid cursor-pointer text-white hover:text-customGold max-2xl:text-sm text-center px-8 max-desktop:px-6 max-lg:px-4"
                >
                  DRIVE
                </Link>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottom"
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                      activeTab === "ABOUT" ? "text-customGold" : "text-white"
                    } max-2xl:text-sm text-end flex px-8 max-desktop:px-6 max-lg:px-4`}
                  >
                    ABOUT
                    <FaChevronDown className="ml-1 mt-1" />
                  </a>
                </Dropdown>
                <Link
                  to="/contact"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "CONTACTS" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center px-8 max-desktop:px-6 max-lg:px-4`}
                >
                  CONTACT
                </Link>
              </div>
            </Row>
          </Col>
          <Col md={8} sm={2} xs={2} className="self-center">
            <div className="flex justify-end items-center">
              <button
                className="uppercase bg-none py-4 xl:px-12 px-4 text-white rounded-lg text-sm font-bold max-xl:text-xs border border-white max-md:hidden"
                onClick={() => navigate("/register")}
              >
                REGISTER
              </button>
              <button
                className="uppercase bg-button py-4 xl:px-12 px-4 text-white rounded-lg text-sm font-bold max-xl:text-xs mx-4 max-md:hidden"
                onClick={() => navigate("/signIn")}
              >
                SIGN IN
              </button>
              <div onClick={showDrawer} className="md:hidden">
                <IoMenu size={36} className="text-white" />
              </div>
            </div>
          </Col>
          <Drawer title="Menu" onClose={onClose} open={open}>
            <div
              to="/"
              className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4"
            >
              <Link to="/">HOME</Link>
            </div>
            <div className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4 ">
              <Link to="/viewRide">RIDE</Link>
            </div>
            <div className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4 ">
              <Link to="/signIn">DRIVE</Link>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="text-lg font-euclid cursor-pointer text-customGold hover:text-customBlack flex mb-4"
              >
                ABOUT US <FaChevronDown className="ml-1 mt-1" />
              </a>
            </Dropdown>
            <div className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4 ">
              <Link to="/contact">CONTACT</Link>
            </div>
            <div className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4 ">
              <Link to="/register">REGISTER</Link>
            </div>
            <div className="text-xl font-euclid cursor-pointer text-customGold hover:text-customBlack mb-4 ">
              <Link to="/signIn">SIGN IN</Link>
            </div>
          </Drawer>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;

{
  /* <Col md={4} className="text-center">
                <Link
                  to="/"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "HOME" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center`}
                >
                  HOME
                </Link>
              </Col>
              <Col md={4} className="text-center">
                <Link
                  to="/viewRide"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "RIDE" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center`}
                >
                  RIDE
                </Link>
              </Col>
              <Col md={4} className="text-center">
                <Link
                  to="/signIn"
                  className="text-lg font-euclid cursor-pointer text-white hover:text-customGold max-2xl:text-sm text-center"
                >
                  DRIVE
                </Link>
              </Col>
              <Col md={5} className="flex justify-center">
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottom"
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                      activeTab === "ABOUT" ? "text-customGold" : "text-white"
                    } max-2xl:text-sm text-end flex`}
                  >
                    ABOUT
                    <FaChevronDown className="ml-1 mt-1"/>
                  </a>
                </Dropdown>
              </Col>
              <Col md={5} className="text-center">
                <Link
                  to="/contact"
                  className={`text-lg font-euclid cursor-pointer hover:text-customGold ${
                    activeTab === "CONTACTS" ? "text-customGold" : "text-white"
                  } max-2xl:text-sm text-center`}
                >
                  CONTACTS
                </Link>
              </Col> */
}
