import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Layout/Header";
import { ImageUrl } from "../../config/helper";

const ForgotPassword1 = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      navigate("/forgotPassword/otp");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <Row
        justify="center"
        style={{
          backgroundImage: `url('${ImageUrl("register-back.png")}')`, // Setting background color to black with 90% opacity
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="flex items-center justify-center min-h-screen"
      >
        <Col lg={8} md={14} xs={22} className="flex flex-col justify-center my-52 max-sm:my-32">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg py-16 px-8 max-lg:px-2 ">
            <h3 className="mt-4 font-tavi text-3xl text-black max-lg:text-xl">
              Forgot Password
            </h3>
            <p className="capitalize font-euclid text-sm text-[#34324B] mb-8 max-xl:text-xs text-center">
              Enter your email to recover your password
            </p>
            <Form
              form={form}
              name="normal_login"
              onFinish={onFinish}
              layout="vertical"
              className="w-full px-8 max-xl:px-2"
            >
              <Row justify="center" gutter={[16, 16]}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    name="email"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Email Address
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                      autoComplete={false}
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Col md={12}>
                  <Form.Item className="self-center text-center">
                    <Button
                      htmlType="submit"
                      className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-12 h-[50px] hover:text-customGold text-center text-sm"
                    >
                      {loading ? "LOADING..." : "CONTINUE"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Link
                  to="/signIn"
                  className="font-euclid text-black text-sm border-b-2 border-black font-semibold mt-4 hover:text-black"
                >
                  Back to Sign In
                </Link>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword1;
