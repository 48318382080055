import { Divider } from "antd";
import { MdDateRange } from "react-icons/md";
import { FaHourglassStart } from "react-icons/fa";
import { BsCreditCardFill } from "react-icons/bs";
const RideComponent3 = () => {
  return (
    <div className="border border-gray-300 py-8 px-4 flex flex-col justify-center items-start w-full">
      <h5 className="capitalize font-tavi text-xl text-black mb-4 max-sm:text-lg">
        Benefits of reserving a ride
      </h5>
      <div className="w-full flex justify-start items-center py-3">
        <MdDateRange size={24} className="text-customGold mr-3" />
        <p className="text-customLightGray text-sm font-semibold max-sm:text-xs font-euclid">
          Choose your exact pickup time up to 90 days in advance
        </p>
      </div>
      <Divider />
      <div className="w-full flex justify-start items-center py-3">
        <FaHourglassStart size={24} className="text-customGold mr-3" />
        <p className="text-customLightGray text-sm font-semibold max-sm:text-xs font-euclid">
          Extra wait time included to meet your ride
        </p>
      </div>
      <Divider />
      <div className="w-full flex justify-start items-center py-3">
        <BsCreditCardFill size={24} className="text-customGold mr-3" />
        <p className="text-customLightGray text-sm font-semibold max-sm:text-xs font-euclid">
          Cancel at no charge up to 60 minutes in advance
        </p>
      </div>
    </div>
  );
};

export default RideComponent3;
