import React from "react";
import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import AboutComponent2 from "../../components/AboutComponents/AboutComponent2/AboutComponent2";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";
import HomeComponent6 from "../../components/HomeComponents/HomeComponent6/HomeComponent6";
import { ImageUrl } from "../../config/helper";
import TeamsComponent1 from "../../components/TeamsComponents/TeamsComponent1";

const About = () => {
  return (
    <div>
      <AboutComponent1
        image={ImageUrl("about-banner.png")}
        heading="About Us"
      />
      <AboutComponent2 />
      {/* <TeamsComponent1 />
      <HomeComponent4 />
      <HomeComponent6 /> */}
    </div>
  );
};

export default About;
