import { Col, DatePicker, Row, Select } from "antd";
import { ImLocation2 } from "react-icons/im";
import { BiCurrentLocation } from "react-icons/bi";
import { FaClock } from "react-icons/fa6";
import { BsPersonFill } from "react-icons/bs";
import GoogleMapReact from "google-map-react";
import { Option } from "antd/es/mentions";
import { MdOutlineMyLocation } from "react-icons/md";
import { useState } from "react";
import ChoosePlaceModal from "../../Modals/ChoosePlaceModal";
import { IoIosAddCircle, IoIosArrowForward } from "react-icons/io";
import SwitchRiderModal from "../../Modals/SwitchRiderModal";
import NewRiderModal from "../../Modals/NewRiderModal";
import { useNavigate } from "react-router-dom";

const RideComponent1 = () => {
  const [switchRiderModal, setSwitchRiderModal] = useState(false);
  const [newRiderModal, setNewRiderModal] = useState(false);
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const [choosePlaceModal, setChoosePlaceModal] = useState(false);
  const navigate = useNavigate();
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Row justify="center">
        <Col xs={24}>
          <div className="border border-gray-300 py-8 px-4 flex flex-col justify-center items-start">
            <h5 className="font-tavi text-3xl text-black mb-4">Get Ride</h5>
            <div className="relative w-full selection">
              <BiCurrentLocation
                className="absolute top-4 left-2 z-10 text-customGold"
                size={18}
              />
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={
                  <p className="pl-4 text-[#263F44]">Pickup Location</p>
                }
                onChange={handleChange}
                className="font-euclid text-xs text-customGray h-[50px]"
              >
                {/* <Option className="custom-option" value="Option1">
                  Option 1
                </Option>
                <Option className="custom-option" value="Option2">
                  Option 2
                </Option>
                <Option className="custom-option" value="Option3">
                  Option 3
                </Option> */}
              </Select>
            </div>

            <div
              className="relative w-full selection my-4"
              onClick={() => setChoosePlaceModal(true)}
            >
              <ImLocation2
                className="absolute top-4 left-2 z-10 text-customGold"
                size={18}
              />
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={
                  <p className="pl-4 text-[#263F44]">Dropoff Location</p>
                }
                onChange={handleChange}
                className="font-euclid text-xs text-customGray h-[50px]"
                suffixIcon={<IoIosAddCircle size={18} />}
              >
                {/* <Option className="custom-option" value="Option1">
                  Option 1
                </Option>
                <Option className="custom-option" value="Option2">
                  Option 2
                </Option>
                <Option className="custom-option" value="Option3">
                  Option 3
                </Option> */}
              </Select>
            </div>

            <div className="relative w-full selection">
              <FaClock
                className="absolute top-4 left-2 z-10 text-customGold"
                size={18}
              />
              <DatePicker
                onChange={onChange}
                className="font-euclid text-xs  text-customGray h-[50px] w-full"
                placeholder="Pickup Now"
                
              />
            </div>

            <div
              className="flex justify-between my-4 rounded-lg py-3 px-2 bg-[#e9e9e9] cursor-pointer"
              onClick={() => setSwitchRiderModal(true)}
            >
              <div className="flex justify-center items-center">
                <BsPersonFill className=" text-customGold" size={18} />
                <p className="font-euclid text-sm ml-1 text-[#263F44]">For Me</p>
              </div>
              <IoIosArrowForward size={18} className="ml-4 text-gray-400" />
            </div>

            <button
              className="uppercase bg-button py-4 px-8 xl:px-16 text-white rounded-lg text-sm font-bold max-lg:text-xs max-xl:px-4 w-full"
              onClick={() => navigate("/confirmationRide")}
            >
              SEARCH ride
            </button>
          </div>
        </Col>
      </Row>
      {switchRiderModal && (
        <SwitchRiderModal
          switchRiderModal={switchRiderModal}
          setSwitchRiderModal={setSwitchRiderModal}
          newRiderModal={newRiderModal}
          setNewRiderModal={setNewRiderModal}
        />
      )}
      {choosePlaceModal && (
        <ChoosePlaceModal
          choosePlaceModal={choosePlaceModal}
          setChoosePlaceModal={setChoosePlaceModal}
        />
      )}
      {newRiderModal && (
        <NewRiderModal
          newRiderModal={newRiderModal}
          setNewRiderModal={setNewRiderModal}
        />
      )}
    </div>
  );
};

export default RideComponent1;
