import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import Swal from "sweetalert2";
import Header from "../../components/Layout/Header";
import { ImageUrl } from "../../config/helper";

const ForgotPassword2 = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      navigate("/forgotPassword/changePassword");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <Row
        justify="center"
        style={{
          backgroundImage: `url('${ImageUrl("register-back.png")}')`, // Setting background color to black with 90% opacity
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="flex items-center justify-center min-h-screen"
      >
        <Col xl={8} md={16} xs={22} className="flex flex-col justify-center my-52 max-sm:my-32">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg py-16 px-8">
            <h3 className="mt-4 font-tavi text-3xl text-black max-md:text-xl">
              Verification Code
            </h3>
            <p className="capitalize font-euclid text-sm text-[#34324B] mb-8 max-md:text-xs text-center">
              An email has been sent to you with a verification code
            </p>
            <div className="flex flex-col items-center justify-center">
              <VerificationInput
                length={4}
                placeholder="_"
                validChars="0-9"
                classNames={{
                  character: "verification-input",
                  characterSelected: "verification-input-focused",
                }}
              />
              <div className="flex justify-end items-end w-full">
                <Link className="text-[#0B7FC3] text-sm border-b-2 border-[#0B7FC3] mt-4 hover:text-[#0B7FC3] flex justify-end">
                  Resend Code
                </Link>
              </div>
            </div>
            <button
              htmlType="submit"
              onClick={() => navigate("/forgotPassword/changePassword")}
              className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-14 h-[50px] hover:text-white text-center text-sm mt-8 rounded-lg max-sm:text-xs"
            >
              {loading ? "LOADING..." : "CONTINUE"}
            </button>
            <Link
              to="/signIn"
              className="font-euclid text-black text-sm border-b-2 border-black font-semibold mt-8 hover:text-black"
            >
              Back to Sign In
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword2;
