import { Col, Row } from "antd";
import Header from "../../Layout/Header";

const AboutComponent1 = ({ image, heading }) => {
  return (
    // <Row
    //   justify="center"
    //   style={{
    //     backgroundImage: "url('./assets/images/about-banner.png')", // Setting background color to black with 90% opacity
    //   }}
    //   className="py-64 relative"
    // >
    //   <Col md={22}>
    //     <Header />
    //   </Col>
    // </Row>

    <div
      className="relative bg-no-repeat bg-cover bg-center"
      style={{
        backgroundImage: `url(${image})`, // Setting background color to black with 90% opacity
      }}
    >
      <Header />
      <Row className="desktop:py-56 md:py-40 py-32" justify="center">
        <Col md={24}>
          <h5 className="text-5xl max-desktop:text-4xl font-tavi text-white text-center pt-10">
            {heading}
          </h5>
        </Col>
      </Row>
    </div>
  );
};

export default AboutComponent1;
