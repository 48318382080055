import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import ContactComponent1 from "../../components/ContactComponents/ContactComponent1";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";
import { ImageUrl } from "../../config/helper";

const Contact = () => {
  return (
    <div>
      <AboutComponent1 image={ImageUrl("contact-back.png")} heading="Contact" />
      <ContactComponent1 />
      <HomeComponent4 />
    </div>
  );
};

export default Contact;
