import { Col, Divider, Row } from "antd";
import { ImageUrl } from "../../config/helper";
import { BsPersonFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineAddCircle } from "react-icons/md";
import { FaSuitcase } from "react-icons/fa";
import { useState } from "react";
import PaymentModal from "../../Modals/PaymentModal";

const Wallet = () => {
  const [paymentModal, setPaymentModal] = useState(false);
  return (
    <div>
      <Row justify="center" className="py-16 max-sm:py-8">
        <Col md={10} sm={22} xs={22}>
          <div className="flex flex-col justify-center items-center">
            <h3 className="text-center font-tavi text-black text-4xl max-sm:text-2xl">
              Wallet
            </h3>
            <img
              src={ImageUrl("credit-card.png")}
              alt="card"
              className="mt-[-10px]"
            />
          </div>
          <div className="flex flex-col justify-center items-center border-y border-gray-300 py-12">
            <h5 className="text-center font-tavi text-black text-2xl max-sm:text-lg">
              Payment Default
            </h5>
            <div className="flex justify-center items-center mt-4">
              <div className="flex flex-col justify-center items-center py-4 w-[170px] max-sm:w-[130px]  rounded-lg shadow-lg sm:mr-8 mr-2">
                <BsPersonFill size={36} className="text-[#82828B]" />
                <h6 className="font-euclid text-sm font-semibold text-customBlack my-1 max-sm:text-xs">
                  Personal
                </h6>
                <p className="font-euclid text-xs text-customGray font-semibold text-center max-sm:text-[10px]">
                  American Express 2008
                </p>
              </div>
              <div className="flex flex-col justify-center items-center py-4 w-[170px] rounded-lg shadow-lg max-sm:w-[130px]">
                <FaSuitcase size={36} className="text-[#82828B]" />
                <h6 className="font-euclid text-sm font-semibold text-customBlack my-1 max-sm:text-xs">
                  Business
                </h6>
                <p className="font-euclid text-xs text-customGray font-semibold text-center max-sm:text-[10px]">
                  Business Profile
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h5 className="text-center font-tavi text-black text-2xl max-sm:text-lg my-8">
              Payment Method
            </h5>
            <div className="bg-white px-8 rounded-lg shadow-lg w-full">
              <div className="flex justify-between items-center my-4">
                <div className="flex justify-center items-center">
                  <img src={ImageUrl("discover.png")} alt="card" />
                  <p className="font-euclid text-sm font-semibold text-orange-500 ml-2 max-sm:text-xs">
                    Discover 6723
                  </p>
                </div>
                <IoIosArrowForward size={24} className="text-[#82828B]" />
              </div>
              <Divider />
              <div className="flex justify-between items-center my-4">
                <div className="flex justify-center items-center">
                  <img
                    src={ImageUrl("express.png")}
                    alt="card"
                    className="border border-blue-800"
                  />
                  <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
                    American Express 2008
                  </p>
                </div>
                <IoIosArrowForward size={24} className="text-[#82828B]" />
              </div>
              <Divider />
              <div className="flex justify-between items-center my-4">
                <div className="flex justify-center items-center">
                  <img
                    src={ImageUrl("visa.png")}
                    alt="card"
                    className="border border-black"
                  />
                  <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
                    Visa 9106
                  </p>
                </div>
                <IoIosArrowForward size={24} className="text-[#82828B]" />
              </div>
              <Divider />
              <div className="flex justify-between items-center my-4">
                <div className="flex justify-center items-center">
                  <img
                    src={ImageUrl("apple.png")}
                    alt="card"
                    className="border border-black"
                  />
                  <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
                    Apply Pay
                  </p>
                </div>
                <IoIosArrowForward size={24} className="text-[#82828B]" />
              </div>
              <Divider />
              <div className="flex justify-start items-center my-4">
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => setPaymentModal(true)}
                >
                  <MdOutlineAddCircle className="text-customGray" size={24} />
                  <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
                    Add Payment Method
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {paymentModal && (
        <PaymentModal
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
        />
      )}
    </div>
  );
};

export default Wallet;
