import { Col, Divider, Row } from "antd";
import { useState } from "react";
import { MdMyLocation } from "react-icons/md";
import { RiEdit2Line, RiHomeSmile2Fill } from "react-icons/ri";
import { FaSuitcase } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { PiAirplaneTiltFill } from "react-icons/pi";

const RideComponentAirport = () => {
  const [startFocused, setStartFocused] = useState(false);
  const [destinationFocused, setDestinationFocused] = useState(false);

  const handleStartFocus = () => {
    setStartFocused(true);
  };

  const handleStartBlur = () => {
    setStartFocused(false);
  };

  const handleDestinationFocus = () => {
    setDestinationFocused(true);
  };

  const handleDestinationBlur = () => {
    setDestinationFocused(false);
  };

  const items = [
    {
      heading: "Beaumont Municipal Airport",
      subheading: "455 Keith Rd, Beaumont",
    },
    {
      heading: "Easterwood Airport",
      subheading: "1 Mckenzie, Terminal Blvd, Collage Station",
    },
    {
      heading: "William P. Hobby Airport",
      subheading: "77800 Airport Blvd, houston",
    },
  ];

  const CustomBox = ({ heading, subheading }) => {
    return (
      <>
        <div className="flex justify-between items-center w-full my-6 px-2">
          <div className="flex justify-center cursor-pointer">
            <PiAirplaneTiltFill
              size={24}
              className="self-center mr-2 text-[#E28C06]"
            />
            <div className="flex flex-col items-start justify-center">
              <h5 className="uppercase font-euclid text-sm max-xl:text-xs font-bold text-customBlack">
                {heading}
              </h5>
              <p className="font-euclid text-xs max-sm:text-[10px] text-customLightGray">
                {subheading}
              </p>
            </div>
          </div>
        </div>
        <Divider />
      </>
    );
  };

  return (
    <Row justify="center">
      <Col xs={24}>
        <div className="border border-gray-300 py-8 px-4 flex flex-col justify-start items-start sm:pb-64">
          <h5 className="font-tavi text-3xl text-black mb-4">Get a Ride</h5>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex justify-start items-center rounded-sm border border-gray-300 bg-white p-3 w-full">
              <MdMyLocation
                size={24}
                className={
                  startFocused
                    ? "text-customGold transition-colors"
                    : "text-red-600 transition-colors"
                }
              />
              <div className="flex flex-col items-start justify-center ml-2 w-full">
                <p
                  className={`font-euclid text-sm max-sm:text-xs ${
                    startFocused ? "text-customGold" : "text-customBlack "
                  } font-bold transition-colors`}
                >
                  Start
                </p>
                <input
                  className="w-full font-euclid text-sm max-sm:text-xs text-customGray font-bold focus:border-none outline-none"
                  placeholder="Current Location"
                  onFocus={handleStartFocus}
                  onBlur={handleStartBlur}
                />
              </div>
            </div>
            <div className="flex justify-start items-center rounded-sm border border-gray-300 bg-white p-3 w-full">
              <MdMyLocation
                size={24}
                className={
                  destinationFocused
                    ? "text-customGold transition-colors"
                    : "text-red-600 transition-colors"
                }
              />
              <div className="flex flex-col items-start justify-center ml-2 w-full">
                <p
                  className={`font-euclid text-sm max-sm:text-xs ${
                    destinationFocused ? "text-customGold" : "text-customBlack "
                  } font-bold transition-colors`}
                >
                  Destination
                </p>
                <input
                  className="w-full font-euclid text-sm max-sm:text-xs text-customGray font-bold focus:border-none outline-none"
                  placeholder="Your Destination"
                  onFocus={handleDestinationFocus}
                  onBlur={handleDestinationBlur}
                />
              </div>
            </div>
            {items.map((item) => (
              <CustomBox heading={item.heading} subheading={item.subheading} />
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RideComponentAirport;
