import { Col, Drawer, Dropdown, Row } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { ImageUrl } from "../../config/helper";
import { FaSuitcase, FaMapPin, FaBus } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { IoCarSport, IoPin } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdJet } from "react-icons/io";
import { RiWallet3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaAward } from "react-icons/fa";
import { RiSteering2Line } from "react-icons/ri";
import { IoSettingsSharp } from "react-icons/io5";
import ProfileModal from "../../Modals/ProfileModal";

const Header = () => {
  const [profileModal, setProfileModal] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Extract the pathname from the location object
    const currentPath = location.pathname;

    // Set the activeTab state based on the current path
    if (currentPath === "/ride") {
      setActiveTab("RIDE");
    } else if (currentPath === "/recent") {
      setActiveTab("RECENT");
    } else if (currentPath === "/shortcut") {
      setActiveTab("SHORTCUT");
    } else if (currentPath === "/airport") {
      setActiveTab("AIRPORT");
    } else if (currentPath === "/transit") {
      setActiveTab("TRANSIT");
    } else {
      setActiveTab(""); // If none of the above, reset activeTab
    }
  }, [location.pathname]);
  const items = [
    {
      key: "1",
      label: (
        <Link
          to="/wallet"
          className=" font-euclid cursor-pointer hover:text-customGold text-sm font-semibold text-center flex justify-start items-center ml-2 py-3 pr-4"
        >
          <RiWallet3Fill size={24} className="mr-2 text-[#576C7E]" />
          <p className="text-[#576C7E]">Wallet</p>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/giftCard"
          className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex justify-start items-center ml-2 py-3"
        >
          <FaGift size={24} className="mr-1 text-[#576C7E]" />
          <p className="text-[#576C7E]">Gift Cards</p>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          to="/rewards"
          className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex justify-start items-center ml-2 py-3"
        >
          <FaAward size={24} className="mr-1 text-[#576C7E]" />
          <p className="text-[#576C7E]">Rewards</p>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link
          to="/ride"
          className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex justify-start items-center ml-2 py-3 pr-4"
        >
          <RiSteering2Line size={24} className="mr-1 text-[#576C7E]" />
          <p className="text-[#576C7E]">Driver With BLR</p>
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          to="/setting"
          className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex justify-start items-center ml-2 py-3"
        >
          <IoSettingsSharp size={24} className="mr-1 text-[#576C7E]" />
          <p className="text-[#576C7E]">Settings</p>
        </Link>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Row
        justify="center"
        className="bg-white border-b-2 border-gray-200 max-sm:py-4"
      >
        <Col xxl={20} md={22} sm={22} xs={22}>
          <Row justify="center">
            <Col lg={2} sm={4} xs={4} className="self-center">
              <img
                src={ImageUrl("logo-1.png")}
                alt="logo"
                className="2xl:mb-[-40px]"
              />
            </Col>
            <Col lg={0} sm={8} xs={8}></Col>
            <Col lg={16} sm={0} xs={0} className="self-center">
              <Row justify="left" className="py-8">
                <div className="px-10 max-desktop:px-6">
                  <Link
                    to="/ride"
                    className={`font-euclid cursor-pointer ${
                      activeTab === "RIDE"
                        ? "text-customGold"
                        : "text-customGray"
                    } hover:text-customGold text-sm font-semibold text-center flex justify-center items-center`}
                  >
                    <IoCarSport size={24} className="mr-1 " />
                    <p>Ride</p>
                  </Link>
                </div>
                <div className="px-10 max-desktop:px-6">
                  <Link
                    to="/recent"
                    className={`font-euclid cursor-pointer ${
                      activeTab === "RECENT"
                        ? "text-customGold"
                        : "text-customGray"
                    } hover:text-customGold text-sm font-semibold text-center flex justify-center items-center`}
                  >
                    <FaLocationDot size={24} className="mr-1" />
                    <p>Recent</p>
                  </Link>
                </div>

                <div className="px-10 max-desktop:px-6">
                  <Link
                    to="/shortcut"
                    className={`font-euclid cursor-pointer ${
                      activeTab === "SHORTCUT"
                        ? "text-customGold"
                        : "text-customGray"
                    } hover:text-customGold text-sm font-semibold text-center flex justify-center items-center`}
                  >
                    <FaMapPin size={24} className="mr-1" />
                    <p>Shortcuts</p>
                  </Link>
                </div>
                <div className="px-10 max-desktop:px-6">
                  <Link
                    to="/airport"
                    className={`font-euclid cursor-pointer ${
                      activeTab === "AIRPORT"
                        ? "text-customGold"
                        : "text-customGray"
                    } hover:text-customGold text-sm font-semibold text-center flex justify-center items-center`}
                  >
                    <IoMdJet size={24} className="mr-1" />
                    <p>Airports</p>
                  </Link>
                </div>
                <div className="px-10 max-desktop:px-6">
                  <Link
                    to="/transit"
                    className={`font-euclid cursor-pointer ${
                      activeTab === "TRANSIT"
                        ? "text-customGold"
                        : "text-customGray"
                    } hover:text-customGold text-sm font-semibold text-center flex justify-center items-center`}
                  >
                    <FaBus size={24} className="mr-1" />
                    <p>Transit</p>
                  </Link>
                </div>
              </Row>
            </Col>
            <Col lg={6} sm={12} xs={12} className="self-center">
              <div className="flex justify-end items-center text-customGray mr-2 hover:text-customGold">
                <IoIosNotifications
                  size={28}
                  className="text-customGray hover:text-customGold cursor-pointer"
                  onClick={() => navigate("/notification")}
                />
                <Link
                  to="/register"
                  className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm text-center mx-4 flex justify-center font-semibold max-tablet:hidden"
                >
                  <FaSuitcase className="mr-1 self-center" size={24} />
                  <p className="mt-1">My Trips</p>
                </Link>
                <div className="font-euclid cursor-pointer text-customGray text-sm text-center flex mx-2">
                  <img
                    src={ImageUrl("profile.png")}
                    className="h-[50px] w-[50px] max-sm:h-[40px] max-sm:w-[40px]"
                    onClick={() => setProfileModal(true)}
                  />{" "}
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                    className=""
                  >
                    <FaChevronDown className="ml-2 mt-5 max-sm:mt-3 max-sm:ml-1 hover:text-customGold" />
                  </Dropdown>
                </div>
                <div onClick={showDrawer} className="tablet:hidden">
                  <IoMenu size={24} className="text-customGray" />
                </div>
              </div>
            </Col>
            <Drawer title="Menu" onClose={onClose} open={open}>
              <Link
                to="/ride"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex"
              >
                <IoCarSport size={24} className="mr-1" />
                <p>Ride</p>
              </Link>

              <Link
                to="/recent"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex my-2"
              >
                <FaLocationDot size={24} className="mr-1" />
                <p>Recent</p>
              </Link>
              <Link
                to="/shortcut"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex "
              >
                <FaMapPin size={24} className="mr-1" />
                <p>Shortcuts</p>
              </Link>
              <Link
                to="/airport"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex my-2"
              >
                <IoMdJet size={24} className="mr-1" />
                <p>Airports</p>
              </Link>
              <Link
                to="/transit"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex"
              >
                <FaBus size={24} className="mr-1" />
                <p>Transit</p>
              </Link>
              <Link
                to="/"
                className=" font-euclid cursor-pointer text-customGray hover:text-customGold text-sm font-semibold text-center flex my-2"
              >
                <FaSuitcase size={24} className="mr-1" />
                <p>My Trips</p>
              </Link>
            </Drawer>
          </Row>
        </Col>
      </Row>
      {profileModal && (
        <ProfileModal
          profileModal={profileModal}
          setProfileModal={setProfileModal}
        />
      )}
    </div>
  );
};

export default Header;
