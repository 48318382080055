import { Divider, Modal } from "antd";
import { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { RiEdit2Line } from "react-icons/ri";
import AddPlaceModal from "./AddPlaceModal";
import { MdPerson } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import NewRiderModal from "./NewRiderModal";
import { MdRadioButtonChecked } from "react-icons/md";

const SwitchRiderModal = ({
  switchRiderModal,
  setSwitchRiderModal,
  setNewRiderModal,
}) => {
  const [riderSelected, setRiderSelected] = useState(1);
  const items = [
    {
      index: 1,
      heading: "Me",
      icon: <MdPerson size={28} />,
    },
    {
      index: 2,
      heading: "Order ride for someone else",
      icon: <BsPeopleFill size={28} />,
    },
  ];

  useEffect(() => {
    if (riderSelected === 2) {
      setNewRiderModal(true);
      setSwitchRiderModal(false);
    }
  }, [riderSelected]);

  const CustomButton = ({ heading, index, icon }) => {
    return (
      <div
        className={`flex justify-between items-center w-full py-6 px-12 cursor-pointer ${
          riderSelected === index ? "bg-[#F6DDB5]" : ""
        }`}
        onClick={() => {
          setRiderSelected(index);
        }}
      >
        <div className="flex justify-center items-center">
          {icon}
          <h5 className="capitalize font-euclid text-sm max-sm:text-xs font-bold text-customBlack ml-2">
            {heading}
          </h5>
        </div>
        {riderSelected === index && (
          <MdRadioButtonChecked
            size={28}
            className="self-center text-customGold"
          />
        )}
      </div>
    );
  };

  return (
    <div className="">
      <Modal
        centered
        open={switchRiderModal}
        footer={null}
        onCancel={() => setSwitchRiderModal(false)}
      >
        <div className="flex flex-col w-full">
          <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg my-4 text-center">
            Switch Rider
          </h3>
          <div className="flex flex-col justify-center items-center mx-[-24px]">
            {items.map((item) => (
              <CustomButton
                heading={item.heading}
                index={item.index}
                icon={item.icon}
              />
            ))}
          </div>

          <button
            className="uppercase bg-button py-4 w-full text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:py-2 max-md:px-4 mt-6"
            onClick={() => {
              setSwitchRiderModal(false);
            }}
          >
            save rider
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SwitchRiderModal;
