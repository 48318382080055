import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../config/helper";

const HomeComponent7 = () => {
  const CustomBox = ({ image, heading, text }) => {
    return (
      <Col md={8}>
        <div className="flex flex-col items-start blogCard">
          <img src={image} alt="blog" className="rounded-lg mb-8 max-2xl:mb-4" />
          <h5 className="capitalize font-tavi text-black text-2xl mb-4 max-2xl:mb-2 max-2xl:text-lg">
            {heading}
          </h5>
          <p className="font-euclid text-sm max-2xl:text-xs">
            {text} <br />
            <Link
              to="/blogDetails"
              className="text-customGold hover:text-customGold"
            >
              READ MORE...
            </Link>
          </p>
        </div>
      </Col>
    );
  };

  const items = [
    {
      image: ImageUrl("blog-1.png"),
      heading: "reimagine the way the world moves",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-2.png"),
      heading: "Rides and beyond",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
    {
      image: ImageUrl("blog-3.png"),
      heading: "How to reduce the carbon footprint",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.",
    },
  ];
  return (
    <Row justify="center">
      <Col md={18} xs={20} className="desktop:py-32 py-16">
        <h5 className="font-tavi text-5xl text-black max-2xl:text-3xl capitalize text-center w-full">
          Latest <span className="text-customGold">Blog</span> posts
        </h5>
        <Row justify="center" gutter={[48, 32]} className="xl:mt-16 mt-8">
          {items.map((item) => (
            <CustomBox
              image={item.image}
              heading={item.heading}
              text={item.text}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default HomeComponent7;
