import { Row, Col } from "antd";
import { ImageUrl } from "../../config/helper/index";

const TeamsComponent1 = ({ heading = true }) => {
  const items = [
    {
      name: "Tom Williams",
      position: "CO-FOUNDER /CEO",
      image: "p-1.png",
    },
    {
      name: "Dmytro S",
      position: "CO-FOUNDER /CTO",
      image: "p-2.png",
    },
    {
      name: "ELISA DEVINE",
      position: "MOBILE DEVELOPER",
      image: "p-3.png",
    },
    {
      name: "TIM DAVID",
      position: "MOBILE DEVELOPER",
      image: "p-4.png",
    },
    {
      name: "Sasha Rose",
      position: "Managing Director",
      image: "p-5.png",
    },
    {
      name: "James Taylor",
      position: "LEAD DEVELOPER",
      image: "p-6.png",
    },
  ];

  const CustomBox = ({ name, position, image }) => {
    return (
      <Col lg={8} md={12} sm={24} xs={24}>
        <div className="">
          <img src={ImageUrl(image)} alt="profile-back" />
        </div>
      </Col>
    );
  };
  return (
    <Row justify="center" className="pt-16">
      <Col md={20} sm={22} xs={22}>
        {heading && (
          <h3 className="font-tavi text-5xl text-customBlack capitalize mb-12 max-md:text-2xl">
            Our <span className="text-customGold">Team</span>
          </h3>
        )}

        <Row justify="center">
          {items.map((item) => (
            <CustomBox
              name={item.name}
              position={item.position}
              image={item.image}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default TeamsComponent1;
