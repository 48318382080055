import { Col, Divider, Row } from "antd";
import { BsCameraFill } from "react-icons/bs";
import { RiEditLine } from "react-icons/ri";
import { ImageUrl } from "../../config/helper";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { FaSuitcase } from "react-icons/fa";
import ChangePasswordModal from "../../Modals/ChangePasswordModal";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../Modals/LogoutModal";

const Setting = () => {
  const [passwordModal, setPasswordModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();

  const setLogout = () => {
    Swal.fire({
      title: "Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Logged Out!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
    });
  };
  return (
    <div>
      <Row justify="center" className="py-16 max-sm:py-8">
        <Col md={20} sm={22} xs={22}>
          <div className="relative flex items-center">
            <h3 className="font-tavi text-4xl max-sm:text-2xl text-customBlack absolute left-1/2 transform -translate-x-1/2">
              Settings
            </h3>
            <button className="absolute right-0 p-2 bg-white shadow-md rounded-md">
              <RiEditLine size={24} />
            </button>
          </div>
          <Row justify="center">
            <Col md={12} sm={22} xs={22}>
              <div className="bg-white px-8 rounded-lg shadow-lg w-full mt-8 py-4">
                <div className="flex flex-col justify-center items-center">
                  <img src={ImageUrl("profile-5.png")} alt="profile" />
                  <div className="flex justify-center items-center p-2 bg-customGold rounded-full mt-[-20px] ml-[50px]">
                    <BsCameraFill size={16} className="text-white" />
                  </div>
                </div>
                <div className="flex justify-between items-center my-4">
                  <div className="flex justify-center items-center">
                    <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                      First Name
                    </p>
                  </div>
                  <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                    Darek
                  </p>
                </div>
                <Divider />
                <div className="flex justify-between items-center my-4">
                  <div className="flex justify-center items-center">
                    <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                      Last Name
                    </p>
                  </div>
                  <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                    James
                  </p>
                </div>
                <Divider />
                <div className="flex justify-between items-center my-4">
                  <div className="flex justify-center items-center">
                    <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                      Email Address
                    </p>
                  </div>
                  <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                    Darek@james.com
                  </p>
                </div>
              </div>

              <div className="bg-white px-8 rounded-lg shadow-lg w-full py-2 mt-6">
                <div className="flex justify-between items-center my-6">
                  <div className="flex justify-center items-center">
                    <RiHomeSmile2Fill
                      size={28}
                      className="text-customLightGray"
                    />
                    <div className="flex flex-col items-start justify-center ml-2">
                      <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                        Home
                      </h6>
                      <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                        Add Home Address
                      </p>
                    </div>
                  </div>
                  <IoIosArrowForward
                    size={24}
                    className="text-customLightGray"
                  />
                </div>
                <Divider />
                <div className="flex justify-between items-center my-6">
                  <div className="flex justify-center items-center">
                    <FaSuitcase size={28} className="text-customLightGray" />
                    <div className="flex flex-col items-start justify-center ml-2">
                      <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                        Work
                      </h6>
                      <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                        Add Work Address
                      </p>
                    </div>
                  </div>
                  <IoIosArrowForward
                    size={24}
                    className="text-customLightGray"
                  />
                </div>
              </div>

              <div className="bg-white px-8 rounded-lg shadow-lg w-full py-2 mt-6">
                <div className="flex justify-between items-center my-4 cursor-pointer">
                  <h6 className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-xs ml-3">
                    Driver Preferences
                  </h6>
                  <div className="flex justify-center items-center">
                    <h6 className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-xs mr-3">
                      None
                    </h6>
                    <IoIosArrowForward
                      size={24}
                      className="text-customLightGray"
                    />
                  </div>
                </div>
                <Divider />
                <div
                  className="flex justify-between items-center my-4 cursor-pointer"
                  onClick={() => setPasswordModal(true)}
                >
                  <h6 className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-xs ml-3">
                    Change Password
                  </h6>
                  <IoIosArrowForward
                    size={24}
                    className="text-customLightGray"
                  />
                </div>
                <Divider />
                <div className="flex justify-between items-center my-4 cursor-pointer">
                  <h6 className="font-euclid text-sm font-semibold text-red-600 max-sm:text-xs ml-3">
                    Delete Account
                  </h6>
                  <IoIosArrowForward size={24} className=" text-red-600" />
                </div>
                <Divider />
                <div className="flex justify-between items-center my-4 cursor-pointer">
                  <h6 className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-xs ml-3">
                    Refer a Friend
                  </h6>
                  <IoIosArrowForward
                    size={24}
                    className="text-customLightGray"
                  />
                </div>
                <Divider />
                <div
                  className="flex justify-between items-center my-4 cursor-pointer"
                  onClick={() => setLogoutModal(true)}
                >
                  <h6 className="font-euclid text-sm font-semibold text-red-600 max-sm:text-xs ml-3">
                    Logout
                  </h6>
                  <IoIosArrowForward size={24} className=" text-red-600" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {passwordModal && (
        <ChangePasswordModal
          passwordModal={passwordModal}
          setPasswordModal={setPasswordModal}
        />
      )}
      {logoutModal && (
        <LogoutModal
          logoutModal={logoutModal}
          setLogoutModal={setLogoutModal}
        />
      )}
    </div>
  );
};

export default Setting;
