import { Col, Divider, Row } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ImageUrl } from "../../config/helper";
import { BsCameraFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { useRef } from "react";

const EditProfile = () => {
  return (
    <Row justify="center" className="py-16 max-sm:py-8">
      <Col md={12} sm={22} xs={22}>
        {/* <div className="relative">
          <button className="absolute left-0 p-2 bg-white shadow-md rounded-md">
            <FaArrowLeftLong size={24} />
          </button>
          <h3 className="font-tavi text-4xl text-customBlack absolute left-1/3">
            Edit Profile
          </h3>
        </div> */}
        <Row justify="center">
          <h3 className="font-tavi text-3xl text-black max-sm:text-xl font-semibold">
            Edit Profile
          </h3>
        </Row>
        <Row justify="center" className="mb-12">
          <Col xxl={10} xl={14} md={18} sm={24} xs={24}>
            <div className="flex flex-col justify-center items-center mt-6 bg-white py-8 px-6 rounded-lg shadow-lg">
              <img src={ImageUrl("profile-5.png")} alt="profile" />
              <div className="flex justify-center items-center p-2 bg-customGold rounded-full mt-[-20px] ml-[50px] cursor-pointer">
                <BsCameraFill size={16} className="text-white" />
              </div>
              <h5 className="my-2 text-center font-euclid text-sm font-semibold text-customBlack">
                Darek James
              </h5>
              <Divider />
              <div className="flex justify-center items-center mt-3">
                <p className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 border-r border-gray-200">
                  50+ <br />
                  <span className="text-xs font-semibold">Rides</span>
                </p>
                <div className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 border-r border-gray-200 flex flex-col justify-center items-center">
                  <div className="flex justify-center">
                    <FaStar className="mr-1 text-customGold" /> 5.0
                  </div>
                  <span className="text-xs font-semibold">Rating</span>
                </div>
                <p className="font-euclid text-sm font-bold text-customBlack text-center px-8 max-sm:px-4 ">
                  4.2 <br />
                  <span className="text-xs font-semibold">Years</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row justify="center" className="mt-12">
          <h5 className="font-tavi text-2xl text-black mb-8 max-sm:text-lg font-semibold">
            Personal Profile
          </h5>
          <div className="bg-white px-8 rounded-lg shadow-lg w-full">
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  First Name
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                Darek
              </p>
            </div>

            <Divider />
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Last Name
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                James
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Email Address
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                Darek@james.com
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Phone Number
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                012 345 6789
              </p>
            </div>
          </div>
        </Row>
        <Divider />
        <Row justify="center" className="mt-12">
          <h5 className="font-tavi text-2xl text-black mb-8 max-sm:text-lg font-semibold">
            Business Profile
          </h5>
          <div className="bg-white px-8 rounded-lg shadow-lg w-full">
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Business Name
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                Badshah Limo
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Business Email
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                Badshah@limo.com
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center my-4">
              <div className="flex justify-center items-center">
                <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                  Business Phone
                </p>
              </div>
              <p className="font-euclid text-customGray text-sm font-bold max-sm:text-xs">
                012 345 6789
              </p>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default EditProfile;
