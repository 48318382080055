import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import BlogComponent2 from "../../components/BlogComponents/BlogComponent2";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";
import { ImageUrl } from "../../config/helper";

const BlogDetails = () => {
  return (
    <div>
      <AboutComponent1
        image={ImageUrl("blog-back.png")}
        heading="Blog Details"
      />
      <BlogComponent2 />
      <HomeComponent4 />
    </div>
  );
};

export default BlogDetails;
