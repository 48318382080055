import { Col, Divider, Row } from "antd";
import { useState } from "react";
import { ImageUrl } from "../../config/helper";
import GiftModal from "../../Modals/GiftModal";

const GiftPage = () => {
  const [loading, setLoading] = useState();
  const [giftModal, setGiftModal] = useState(false);
  return (
    <div>
      <Row justify="center py-16 max-sm:py-8">
        <Col md={16} sm={22} xs={22}>
          <h3 className="text-center font-tavi text-black text-4xl max-sm:text-2xl mb-4">
            Gift Card
          </h3>
          <div className="p-4 rounded-lg customShadow bg-white flex flex-col items-center justify-center">
            <img src={ImageUrl("gift-back.png")} alt="banner" />
            <h3 className="capitalize font-euclid text-xl font-semibold text-customBlack my-4 max-sm:text-lg">
              Give the gift of rides
            </h3>
            <p className="font-euclid text-sm font-semibold text-customGray text-center max-sm:text-xs">
              Send gift cards to your loved ones for BLR rides
            </p>
            <button
              className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-14 max-sm:px-8 h-[50px] max-sm:text-xs hover:text-customGold text-center text-sm rounded-lg my-6"
              onClick={() => setGiftModal(true)}
            >
              BUY A GIFT CARD"
            </button>
            <Divider className="bg-gray-200" />
            <h3 className="capitalize font-euclid text-xl font-semibold text-customBlack my-4 max-sm:text-lg">
              Redeem Your gift Card
            </h3>
            <input
              placeholder="Gift Card Number"
              className="p-3 bg-white shadow-xl hover:border-gray-300 active:border-gray-300 w-[300px] max-sm:w-[200px] rounded-lg"
            />
            <button className="bg-[#F7F6FF] hover:bg-white hover:border-customBlack hover:border-2 font-urban font-bold text-customBlack px-14 h-[50px]  text-center text-sm rounded-lg my-6 max-sm:text-xs">
              {loading ? "LOADING..." : "REDEEM"}
            </button>
          </div>
        </Col>
      </Row>
      {giftModal && (
        <GiftModal giftModal={giftModal} setGiftModal={setGiftModal} />
      )}
    </div>
  );
};

export default GiftPage;
