import { Divider, Form, Input, Modal } from "antd";
import { useState } from "react";

const ChangePasswordModal = ({ passwordModal = false, setPasswordModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {};
  const handleClick = () => {
    setPasswordModal(false);
  };
  return (
    <div>
      <Modal
        centered
        open={passwordModal}
        footer={null}
        onCancel={() => setPasswordModal(false)}
      >
        <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg text-center my-4">
          Change Password
        </h3>
        <Divider />
        <div className="flex flex-col justify-center items-center bg-white my-4 px-8">
          <Form
            form={form}
            name="normal_login"
            onFinish={onFinish}
            layout="vertical"
            className="w-full"
          >
            <Form.Item
              name="currentPassword"
              label="Current Password"
              rules={[
                {
                  required: true,
                  message: "Please input current password!",
                },
                {
                  min: 8,
                  message:
                    "Password should be at least eight character with at least one numeric, one uppercase and one lowercase character. ",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Current Password"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input new password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter New Password"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Confirm your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
          </Form>
          <button
            className="uppercase bg-button py-4 w-full text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:px-4 mt-6"
            onClick={handleClick}
          >
            set password
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
