import { Col, Divider, Pagination, Row } from "antd";
import { useState } from "react";
import { ImageUrl } from "../../config/helper";

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const getNotifications = async (pageNumber, pageSize) => {
    // setLoading(true);
    // try {
    //   const response = await Get(USERS.getAllUsers, token, {
    //     page: pageNumber
    //       ? pageNumber.toString()
    //       : paginationConfig.pageNumber.toString(),
    //     limit: pageSize
    //       ? pageSize.toString()
    //       : paginationConfig.limit.toString(),
    //   });
    //   // const response = await Get(USERS.getAllUsers, token);
    //   setLoading(false);
    //   console.log("response101", response.data);
    //   if (response?.status) {
    //     setUsers(response?.data?.docs);
    //     //setUsers(response?.data);
    //     setPaginationConfig({
    //       pageNumber: response?.data?.page,
    //       limit: response?.data?.limit,
    //       totalDocs: response?.data?.totalDocs,
    //       totalPages: response?.data?.totalPages,
    //     });
    //   } else {
    //     message.error("Something went wrong!");
    //     console.log("error====>", response);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   setLoading(false);
    // }
  };

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    getNotifications(pageNumber);
  };

  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;
  return (
    <Row justify="center" className="py-16 max-sm:py-8">
      <Col md={12} sm={22} xs={22}>
        <h3 className="font-tavi text-4xl text-customBlack max-sm:text-2xl text-center">
          Notifications
        </h3>
        <Row justify="center" className="">
          <div className="bg-white shadow-lg w-full py-2 mt-6">
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-customYellow">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-5.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Confirm your email address
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Friday, 10:03 AM
                  </p>
                </div>
              </div>
              <p className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-[10px] border-b border-gray-300  whitespace-nowrap">
                Mark as Read
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-white">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-6.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Your ride will be staring in an hour
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Monday, 11:45 AM
                  </p>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-customYellow">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-7.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Confirm your email address
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Friday, 10:03 AM
                  </p>
                </div>
              </div>
              <p className="font-euclid text-sm font-semibold text-customLightGray max-sm:text-[10px] border-b border-gray-300 whitespace-nowrap">
                Mark as Read
              </p>
            </div>
            <Divider />
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-white">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-8.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Confirm your email address
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Friday, 10:03 AM
                  </p>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-white">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-5.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Your ride will be staring in an hour
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Monday, 11:45 AM
                  </p>
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex justify-between items-center py-6 px-8 max-sm:px-2 bg-white">
              <div className="flex justify-center items-center">
                <img
                  src={ImageUrl("profile-6.png")}
                  alt="profile"
                  className="h-[50px] w-[50px]"
                />
                <div className="flex flex-col items-start justify-center ml-3">
                  <h6 className="font-euclid text-sm font-semibold text-customBlack max-sm:text-xs">
                    Confirm your email address
                  </h6>
                  <p className="font-euclid text-xs font-semibold text-customLightGray max-sm:text-[10px]">
                    Friday, 10:03 AM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="mt-8">
          <Col xs={24} md={12}>
            <p className="text-sm max-sm:text-xs font-euclid text-customLightGray font-bold max-sm:mb-2">
              {message}
            </p>
          </Col>
          <Col xs={24} md={12} className="flex justify-end">
            <Pagination
              className="styledPagination"
              onChange={(e) => handlePageChange(e)}
              current={parseInt(paginationConfig.pageNumber)}
              pageSize={paginationConfig.limit}
              total={paginationConfig.totalDocs}
              itemRender={itemRender}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Notification;
