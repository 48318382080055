import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Layout/Header";
import { ImageUrl } from "../../config/helper";
import { TiArrowSortedDown } from "react-icons/ti";

const { Option } = Select;

const Register = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let data = {
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      };

      Swal.fire({
        icon: "success",
        title: "Your Profile has been Registered!",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/signIn");
      }, 1500);

      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <Header />
      <Row
        justify="center"
        style={{
          backgroundImage: `url('${ImageUrl("register-back.png")}')`, // Setting background color to black with 90% opacity
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="flex items-center justify-center min-h-screen"
      >
        <Col xl={12} md={18} xs={22} className="my-52 max-sm:my-32">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg py-8 px-8 max-lg:px-2 ">
            <h3 className="mt-4 font-tavi text-3xl text-black max-md:text-xl">
              Register
            </h3>
            <p className="capitalize font-euclid text-sm text-[#34324B] mb-8 max-md:text-xs text-center">
              Fill Out This form to sign up
            </p>
            <Form
              form={form}
              name="normal_login"
              onFinish={onFinish}
              layout="vertical"
              className="w-full px-8 max-xl:px-2"
            >
              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="firstName"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        First Name
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name"
                      className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="lastName"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Last Name
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                      autoComplete={false}
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="email"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Email Address
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address"
                      className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                      autoComplete={false}
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="phone"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Mobile Number
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <div className="flex justify-center customOption">
                      <Select
                        defaultValue="+12"
                        className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs w-fit mr-2"
                        suffixIcon={<TiArrowSortedDown />}
                        onChange={handleChange}
                        style={{
                          width: "fit-content",
                        }}
                        options={[
                          {
                            label: (
                              <span
                                className="font-euclid text-sm text-black"
                                style={{ margin: "" }}
                              >
                                +12
                              </span>
                            ),
                            value: "+12",
                          },
                          {
                            label: (
                              <span className="font-euclid text-sm text-black">
                                +32
                              </span>
                            ),
                            value: "+32",
                          },
                        ]}
                      />
                      <Input
                        placeholder="Phone Number"
                        className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                        autoComplete={false}
                        type="number"
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="password"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Password
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      className="h-12 text-sm font-euclid bg-gray-100 "
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="confirmPassword"
                    label={
                      <p className="font-euclid text-sm text-[#34324B] font-bold">
                        Confirm Password
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Confirm your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Confirm Password"
                      className="h-12 text-sm font-euclid bg-gray-100"
                      autoComplete={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Col md={12}>
                  <Form.Item className="self-center text-center">
                    <Button
                      htmlType="submit"
                      className="bg-button hover:bg-white hover:border-customGold hover:border-2 font-urban font-bold text-white px-14 h-[50px] hover:text-customBrown text-center text-sm"
                    >
                      {loading ? "LOADING..." : "Register"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" gutter={[16, 16]}>
                <Col md={12} sm={12} xs={24} className="flex justify-center">
                  <button className="uppercase bg-[#1877F2] hover:bg-white hover:border-[#1877F2] hover:border-2 font-urban font-bold text-white px-14 max-xl:px-4 h-[50px] hover:text-[#1877F2] text-center text-sm rounded-lg flex justify-center items-center max-xl:text-[10px] max-sm:text-sm max-sm:w-full">
                    <img
                      src={ImageUrl("face-circular.png")}
                      alt="facebook"
                      className="mr-2"
                    />
                    {loading ? "LOADING..." : "Signup with Facebook"}
                  </button>
                </Col>
                <Col md={12} sm={12} xs={24} className="flex justify-center">
                  <button className="uppercase bg-[#D9D9D9] hover:bg-white hover:border-[#D9D9D9] hover:border-2 font-urban font-bold text-black px-14 max-xl:px-4 h-[50px] hover:text-black text-center text-sm rounded-lg flex justify-center items-center max-xl:text-[10px] max-sm:text-sm max-sm:w-full">
                    <img
                      src={ImageUrl("google.png")}
                      alt="google"
                      className="mr-2"
                    />
                    {loading ? "LOADING..." : "Signup with Google"}
                  </button>
                </Col>
              </Row>
              <Row justify="center">
                <Link
                  to="/signIn"
                  className="font-euclid text-black text-sm border-b-2 border-black font-semibold mt-4 hover:text-black"
                >
                  Back to Sign In
                </Link>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Register;
