import ScrollToTop from "../../components/Layout/ScrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import Header from "../../components/Layout/Header";
import Homepage from "../../views/HomePage/Homepage";
import About from "../../views/About/About";
import Contact from "../../views/Contact/Contact";
import Blogs from "../../views/Blogs/Blogs";
import BlogDetails from "../../views/BlogDetails/BlogDetails";
import Register from "../../views/Register/Register";
import Teams from "../../views/Teams/Teams";
import SignIn from "../../views/SignIn/SignIn";
import ForgotPassword1 from "../../views/ForgotPassword1/ForgotPassword1";
import ForgotPassword2 from "../../views/ForgotPassword2/ForgotPassword2";
import ForgotPassword3 from "../../views/ForgotPassword3/ForgotPassword3";
import UserLayout from "../../components/UserLayout/Layout";
import RidePage from "../../views/RidePage/RidePage";
import GiftPage from "../../views/GiftPage/GiftPage";
import Wallet from "../../views/Wallet/Wallet";
import EditProfile from "../../views/EditProfile/EditProfile";
import Notification from "../../views/Notfication/Notification";
import Setting from "../../views/Setting/Setting";
import Rewards from "../../views/Rewards/Rewards";
import RecentPage from "../../views/RecentPage/RecentPage";
import ShortcutPage from "../../views/ShortcutPage/ShortcutPage";
import AirportPage from "../../views/AirportPage/AirportPage";
import TransitPage from "../../views/TransitPage/TransitPage";
import ConfirmationRide from "../../views/ConfirmationRide/ConfirmationRide";
import ViewRidePage from "../../views/ViewRidePage/ViewRidePage";

function myRouter() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Homepage />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="/blogs"
          element={
            <Layout>
              <Blogs />
            </Layout>
          }
        />

        <Route
          path="/blogDetails"
          element={
            <Layout>
              <BlogDetails />
            </Layout>
          }
        />
        <Route
          path="/team"
          element={
            <Layout>
              <Teams />
            </Layout>
          }
        />
        <Route
          path="/viewRide"
          element={
            <Layout>
              <ViewRidePage />
            </Layout>
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/forgotPassword" element={<ForgotPassword1 />} />
        <Route path="/forgotPassword/otp" element={<ForgotPassword2 />} />
        <Route
          path="/forgotPassword/changePassword"
          element={<ForgotPassword3 />}
        />

        {/* ------------------------------------------------------------------------------------------------------------------------------------- */}

        <Route
          path="/ride"
          element={
            <UserLayout>
              <RidePage />
            </UserLayout>
          }
        />

        <Route
          path="/recent"
          element={
            <UserLayout>
              <RecentPage />
            </UserLayout>
          }
        />
        <Route
          path="/shortcut"
          element={
            <UserLayout>
              <ShortcutPage />
            </UserLayout>
          }
        />
        <Route
          path="/airport"
          element={
            <UserLayout>
              <AirportPage />
            </UserLayout>
          }
        />
        <Route
          path="/transit"
          element={
            <UserLayout>
              <TransitPage />
            </UserLayout>
          }
        />

        <Route
          path="/giftCard"
          element={
            <UserLayout>
              <GiftPage />
            </UserLayout>
          }
        />
        <Route
          path="/wallet"
          element={
            <UserLayout>
              <Wallet />
            </UserLayout>
          }
        />
        <Route
          path="/editProfile"
          element={
            <UserLayout>
              <EditProfile />
            </UserLayout>
          }
        />
        <Route
          path="/notification"
          element={
            <UserLayout>
              <Notification />
            </UserLayout>
          }
        />
        <Route
          path="/setting"
          element={
            <UserLayout>
              <Setting />
            </UserLayout>
          }
        />
        <Route
          path="/rewards"
          element={
            <UserLayout>
              <Rewards />
            </UserLayout>
          }
        />
        <Route
          path="/confirmationRide"
          element={
            <UserLayout>
              <ConfirmationRide />
            </UserLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default myRouter;
