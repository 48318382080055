import { Col, Row, Switch } from "antd";
import React, { useState } from "react";
import { IoPerson } from "react-icons/io5";
import { FaSuitcase } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { BsPersonFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMyLocation } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import RideComponent3 from "./RideComponent3";
import { ImageUrl } from "../../config/helper";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { IoMdRadioButtonOn } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";

const RideComponent2 = () => {
  const [selectedTiimng, setSelectedTiming] = useState(2);
  const items = [
    {
      heading: "in 5 min",
      subheading: "Priority Pickup",
      price: "$70.49",
      index: 1,
    },
    {
      heading: "in 10 min",
      subheading: "Standard",
      price: "$50.25",
      index: 2,
    },
    {
      heading: "in 5- 20 min",
      subheading: "Wait & Save",
      price: "$59.91",
      index: 3,
    },
  ];

  const carDetails = [
    {
      heading: "Extra Comfort",
      subheading: "in 15 min",
      text: "Roomier & quiet",
      price: "$70.49",
      index: 1,
      people: 4,
      time: "7:29 PM",
      image: "demo-2.png",
    },
    {
      heading: "Black SUV",
      subheading: "in 15 min",
      text: "VIP style for up to 6",
      price: "$70.49",
      index: 1,
      people: 6,
      time: "9:58 AM",
      image: "demo-3.png",
    },
  ];

  const CustomCarDetails = ({
    heading,
    subheading,
    price,
    time,
    people,
    image,
    index,
    text,
  }) => {
    return (
      <div
        className="p-4 justify-between flex items-center w-full bg-white"
        onClick={() => setSelectedTiming(index)}
      >
        <div className="flex justify-center items-center">
          <img src={ImageUrl(image)} alt="car" />

          <div className="flex flex-col items-start justify-center ml-2">
            <div className="flex justify-center">
              <h5 className="capitalize font-euclid font-bold text-sm max-sm:text-xs text-customBlack">
                {heading}
              </h5>
              <BsFillPersonFill
                size={16}
                className="text-customLightGray ml-2 mt-[2px]"
              />
              <p className="font-euclid text-xs max-sm:text-[10px] text-customLightGray font-bold mt-[2px]">
                {people}
              </p>
            </div>

            <p className="capitalize font-euclid font-bold text-xs max-sm:text-[10px] text-customLightGray">
              {subheading}
            </p>
            <p className="capitalize font-euclid font-bold text-xs max-sm:text-[10px] text-customLightGray">
              {text}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <p className="font-euclid font-bold text-lg max-sm:text-sm text-customBlack">
            {price}
          </p>
          <p className="capitalize font-euclid font-bold text-[10px] text-customBlack">
            {time}
          </p>
        </div>
      </div>
    );
  };

  const CustomTiming = ({ heading, subheading, price, index }) => {
    return (
      <div
        className={`p-4 justify-between flex items-center cursor-pointer w-full ${
          selectedTiimng === index ? "bg-[#F6DDB5]" : "bg-white"
        }`}
        onClick={() => setSelectedTiming(index)}
      >
        <div className="flex justify-center items-center">
          {selectedTiimng === index ? (
            <IoMdRadioButtonOn size={24} className="text-[#E28C06]" />
          ) : (
            <RiCheckboxBlankCircleLine size={24} className="text-customBlack" />
          )}

          <div className="flex flex-col items-start justify-center ml-2">
            <h5 className="capitalize font-euclid font-bold text-sm max-sm:text-xs text-customBlack">
              {heading}
            </h5>
            <p className="capitalize font-euclid font-bold text-xs max-sm:text-[10px] text-customLightGray">
              {subheading}
            </p>
          </div>
        </div>
        <p className="font-euclid font-bold text-lg max-sm:text-sm text-customBlack">
          {price}
        </p>
      </div>
    );
  };
  return (
    <Row justify="center">
      <Col xs={24}>
        <Row justify="center">
          <Col xl={10} xs={24}>
            <Row justify="center" className="mb-8">
              <div className="border border-gray-300 py-8 px-4 flex flex-col justify-center items-start w-full">
                <h5 className="font-tavi text-3xl text-black mb-4">Get Ride</h5>
                <div className="flex justify-between rounded-lg py-3 px-2 bg-[#e9e9e9] w-full">
                  <div className="flex justify-center items-center">
                    <FaLocationDot className=" text-customGold" size={18} />
                    <p className="font-euclid text-sm text-[#263F44] ml-1">
                      2 W 6th blvd, street 01
                    </p>
                  </div>
                  {/* <IoIosArrowForward size={18} className="ml-4 text-gray-400" /> */}
                </div>

                <div className="flex justify-between my-4 rounded-lg py-3 px-2 bg-[#e9e9e9] w-full">
                  <div className="flex justify-center items-center">
                    <MdOutlineMyLocation
                      className=" text-customGold"
                      size={18}
                    />
                    <p className="font-euclid text-sm text-[#263F44] ml-1">
                      George bush international airport...
                    </p>
                  </div>
                  <IoIosAddCircle
                    size={18}
                    className="ml-4 text-[#263F44] self-center"
                  />
                </div>

                <div className="flex justify-between rounded-lg py-3 px-2 bg-[#e9e9e9] w-full">
                  <div className="flex justify-center items-center">
                    <FaClock className=" text-customGold" size={18} />
                    <p className="font-euclid text-sm text-[#263F44] ml-1">
                      Pickup Now
                    </p>
                  </div>
                  <IoIosArrowForward size={18} className="ml-4 text-[#263F44]" />
                </div>

                <div className="flex justify-between my-4 rounded-lg py-3 px-2 bg-[#e9e9e9]">
                  <div className="flex justify-center items-center">
                    <BsPersonFill className=" text-customGold" size={18} />
                    <p className="font-euclid text-sm text-[#263F44] ml-1">
                      For Me
                    </p>
                  </div>
                  <IoIosArrowForward size={18} className="ml-4 text-[#263F44]" />
                </div>
              </div>
            </Row>
            <Row>
              <RideComponent3 />
            </Row>
          </Col>
          <Col xl={14} xs={24}>
            <div className="flex flex-col items-start justify-center sm:ml-6 mt-16 max-md:mt-4">
              <h5 className="capitalize font-tavi text-3xl text-black mb-4 max-sm:text-lg pl-4">
                Choose a Ride
              </h5>
              <div className="flex justify-start items-center pl-4">
                <img src={ImageUrl("demo-1.png")} alt="demo" />
                <p className="font-euclid uppercase text-customBlack text-sm max-sm:text-xs ml-2 font-bold">
                  Badshah Limo
                </p>
              </div>
              <div className="flex flex-col mt-4 w-full">
                {items.map((item) => (
                  <CustomTiming
                    heading={item.heading}
                    subheading={item.subheading}
                    index={item.index}
                    price={item.price}
                  />
                ))}
              </div>
              <h5 className="capitalize font-tavi text-2xl text-black mb-4 max-sm:text-lg pl-4 my-4">
                More Ways to Get There!
              </h5>
              <div className="flex flex-col w-full">
                {carDetails.map((detail) => (
                  <CustomCarDetails
                    heading={detail.heading}
                    subheading={detail.subheading}
                    index={detail.index}
                    price={detail.price}
                    people={detail.people}
                    text={detail.text}
                    image={detail.image}
                    time={detail.time}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <div className="flex justify-end items-center p-6 bg-white w-full rounded-lg shadow-lg">
            <Switch
              checkedChildren={
                <IoPerson size={16} className="mt-1 text-white" />
              }
              unCheckedChildren={
                <FaSuitcase size={16} className="mt-1 text-customBlack" />
              }
              defaultChecked
            />
            <p className="flex justify-center ml-4 mr-6 text-customGray text-xs max-sm:text-[10px] font-euclid font-bold">
              American Express 2008 <IoIosArrowForward size={18} />
            </p>

            <button className=" bg-button py-2 px-8 xl:px-16 text-white rounded-lg max-md:py-2 max-md:px-4 text-center">
              <h5 className="uppercase font-euclid text-sm max-sm:text-xs font-bold">
                Select Wait & Save
              </h5>
              <p className="font-euclid text-xs max-sm:text-[10px] font-bold">
                Pick in 5-20 min
              </p>
            </button>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default RideComponent2;
