import { Col, Row } from "antd";
import RideComponent1 from "../../components/RideComponents/RideComponent1";
import GoogleMapReact from "google-map-react";
import { ImLocation } from "react-icons/im";
import { ImageUrl } from "../../config/helper";
import AboutComponent1 from "../../components/AboutComponents/AboutComponent1/AboutComponent1";
import HomeComponent4 from "../../components/HomeComponents/HomeComponent4/HomeComponent4";

const Marker = ({ image }) => <div>{image}</div>; // Custom marker component

const ViewRidePage = () => {
  const defaultProps = {
    center: {
      lat: 34.052235,
      lng: -118.243683,
    },
    zoom: 11,
  };

  return (
    <div>
      <AboutComponent1 image={ImageUrl("front-2.png")} heading="Ride Now" />
      <Row justify="center py-16">
        <Col md={22} xs={22}>
          <Row justify="center" gutter={[16, 16]}>
            <Col lg={6} md={8} xs={24}>
              <RideComponent1 />
            </Col>
            <Col lg={18} md={16} xs={24} className="h-screen max-md:h-[550px]">
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }} // Add your Google Maps API key here
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {/* Marker for Main Street */}
                <Marker
                  lat={34.052235}
                  lng={-118.243683}
                  image={<img src={ImageUrl("c-1.png")} alt="car" />}
                />
              </GoogleMapReact>
            </Col>
          </Row>
        </Col>
      </Row>
      <HomeComponent4 />
    </div>
  );
};

export default ViewRidePage;
