import { Divider, Modal } from "antd";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/helper";

const LogoutModal = ({ logoutModal = false, setLogoutModal }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
    setLogoutModal(false);
  };
  const handleCancel = () => {
    setLogoutModal(false);
  };
  return (
    <div>
      <Modal
        centered
        open={logoutModal}
        footer={null}
        onCancel={() => setLogoutModal(false)}
      >
        <div className="flex flex-col justify-center items-center bg-white py-4">
          <img src={ImageUrl("warning.png")} alt="warning" />
          <h3 className="font-tavi text-3xl text-customBlack max-desktop:text-xl text-center mt-4 font-normal">
            Logout Confirmation
          </h3>
          <h5 className="mt-2 mb-4 text-center font-euclid text-sm font-semibold text-customBlack">
            Are you sure you want to logout?
          </h5>
          <div className="flex flex-col items-center justify-center w-44 2xl:w-52">
            <button
              className="uppercase bg-button py-4 text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:px-4 my-4 w-full"
              onClick={handleClick}
            >
              YES
            </button>
            <button
              className="uppercase bg-[#243340] py-4 text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:px-4  w-full"
              onClick={handleCancel}
            >
              no
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LogoutModal;
