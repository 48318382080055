import { Col, Divider, Row } from "antd";
import { IoIosArrowForward } from "react-icons/io";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { ImageUrl } from "../../config/helper";
import { IoGift } from "react-icons/io5";

const Rewards = () => {
  const CustomBox = ({ heading, subheading, text, points }) => {
    return (
      <div className="flex flex-col justify-center items-start pb-4 bg-white rounded-lg shadow-lg w-full mb-4">
        <h5
          className={`bg-purple-600 w-full rounded-t-lg p-4 font-euclid text-sm max-sm:text-xs text-white font-[900]`}
        >
          {heading}
        </h5>
        <div className="flex justify-end bg-white w-full">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg p-2 shadow-lg mr-4 mt-[-25px]">
            <h6 className="font-euclid text-sm max-sm:text-xs text-customBlack font-[900]">
              {points}
            </h6>
            <p className="font-euclid text-sm max-sm:text-xs text-customLightGray">
              Points
            </p>
          </div>
        </div>
        <h6 className="font-euclid text-sm max-sm:text-xs text-customBlack font-[900] px-4">
          {subheading}
        </h6>
        <p className="font-euclid text-xs max-sm:text-[10px] text-customLightGray font-normal px-4 mb-2">
          {text}
        </p>
      </div>
    );
  };

  const items = [
    {
      heading: "$50.00 Vouchers...",
      subheading: "Ride credit of upto $50",
      text: "You need 340 points to unlock this reward",
      points: 400,
      color: "purple",
    },
    {
      heading: "50% Off three rides",
      subheading: "Up to the value of $100",
      text: "You need 200 points to unlock this reward",
      points: 400,
      color: "blue",
    },
    {
      heading: "A free $30 Ride...",
      subheading: "Ride credit of upto $50",
      text: "Valid for 10 days",
      points: 400,
      color: "orange",
    },
  ];
  return (
    <Row justify="center" className="py-16 max-sm:py-8">
      <Col md={12} sm={22} xs={22}>
        <h3 className="text-center font-tavi text-black text-4xl max-sm:text-2xl">
          Rewards
        </h3>
        <Row justify="center max-sm:mt-[-30px] mt-[-20px]">
          <Col
            md={24}
            style={{
              background: `url(${ImageUrl("rewards.png")})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain", // This will maintain the aspect ratio
              width: "100%", // This will make the image responsive
              maxWidth: "500px", // This will prevent the image from exceeding 500px on large screens
              height: "auto", // This will maintain the aspect ratio
            }}
            className="md:py-16 py-16 rounded-lg"
          >
            <div className="flex flex-col justify-center items-center">
              <p className="font-euclid text-sm max-xl:text-[10px] text-white">
                PLATINUM
              </p>
              <h3 className="font-euclid text-6xl max-xl:text-2xl text-white xl:my-1">
                771
              </h3>
              <p className="font-euclid text-sm max-xl:text-[10px] text-white text-center">
                Reward points available!
              </p>
              <button className="uppercase bg-white py-4 px-4 xl:px-8 text-[#1B419B] rounded-lg text-xs font-bold max-xl:text-sm max-md:text-[9px] max-md:py-1 max-md:px-4 md:my-2">
                redeem points
              </button>
            </div>
          </Col>
        </Row>
        <Divider />
        <div className="flex flex-col justify-center items-center mt-8 max-sm:mt-4 w-full">
          {items.map((item) => (
            <CustomBox
              heading={item.heading}
              subheading={item.subheading}
              text={item.text}
              points={item.points}
              color={item.color}
            />
          ))}
          <div className="flex justify-between items-center w-full bg-red-700 py-6 rounded-lg px-4">
            <div className="flex justify-center items-center">
              <IoGift size={34} className="text-white" />
              <div className="flex flex-col items-start justify-center ml-2">
                <h6 className="font-euclid text-sm font-semibold text-white max-sm:text-xs">
                Refer a friend to my ride...
                </h6>
                <p className="font-euclid text-xs font-normal text-white max-sm:text-[10px]">
                Get $20.00 Vouchers
                </p>
              </div>
            </div>
            <IoIosArrowForward size={24} className="text-white" />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Rewards;
