import { Divider, Form, Input, Modal, Select } from "antd";
import { useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

const NewRiderModal = ({ newRiderModal = false, setNewRiderModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {};
  const handleClick = () => {
    setNewRiderModal(false);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Modal
        centered
        open={newRiderModal}
        footer={null}
        onCancel={() => setNewRiderModal(false)}
      >
        <h3 className="font-tavi text-2xl text-customBlack max-sm:text-lg text-center my-4">
          New Rider
        </h3>
        <Divider />
        <div className="flex flex-col justify-center items-center bg-white my-4 px-8">
          <Form
            form={form}
            name="normal_login"
            onFinish={onFinish}
            layout="vertical"
            className="w-full"
          >
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input
                placeholder="Enter First Name"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input
                placeholder="Enter Last Name"
                className="h-12 text-sm font-euclid bg-gray-100 "
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <p className="font-euclid text-sm text-[#34324B] font-bold">
                  Phone Number
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input phone number!",
                },
              ]}
            >
              <div className="flex justify-center customOption">
                <Select
                  defaultValue="+12"
                  className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs w-fit mr-2"
                  suffixIcon={<TiArrowSortedDown />}
                  onChange={handleChange}
                  style={{
                    width: "fit-content",
                  }}
                  options={[
                    {
                      label: (
                        <span
                          className="font-euclid text-sm text-black"
                          style={{ margin: "" }}
                        >
                          +12
                        </span>
                      ),
                      value: "+12",
                    },
                    {
                      label: (
                        <span className="font-euclid text-sm text-black">
                          +32
                        </span>
                      ),
                      value: "+32",
                    },
                  ]}
                />
                <Input
                  placeholder="1234567"
                  className="h-12 text-sm font-euclid bg-gray-100 max-sm:text-xs"
                  autoComplete={false}
                  type="number"
                />
              </div>
            </Form.Item>
          </Form>
          <p className="font-euclid text-sm text-customGray">
            By tapping "Add rider", you confirm that your friend agreed to share
            their contact information with BLR and to receive SMS about this
            trip.
          </p>
          <button
            className="uppercase bg-button py-4 w-full text-white rounded-lg text-sm font-bold max-sm:text-xs max-md:py-2 max-md:px-4 mt-6"
            onClick={handleClick}
          >
            Add rider
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default NewRiderModal;
