import { Col, Row } from "antd";
import { ImWhatsapp } from "react-icons/im";
import { ImageUrl } from "../../../config/helper";

const HomeComponent4 = () => {
  return (
    <Row justify="center" className="bg-white py-18 max-desktop:py-12">
      <Col xxl={20} md={22}>
        <Row justify="center">
          <Col md={12} sm={18} xs={18} className="self-center">
            <div className="flex flex-col justify-center lg:ml-32">
              <h3 className="text-xl font-tavi text-customBlack max-desktop:text-lg capitalize">
                Experience luxury at your{" "}
                <span className="text-customGold">fingertips</span>
              </h3>
              <h3 className="text-3xl font-tavi text-customBlack max-desktop:text-2xl capitalize mt-6">
                download <span className="text-customGold font-bold">BLUS</span>
              </h3>
              <p className="my-8 max-2xl:my-6 font-euclid text-customBlack text-sm">
                Experience convenience like never before with the BLUS app
                powered by Badshah. Effortlessly book premium rides, track your
                chauffeur in real-time, and communicate directly for
                personalized service. Enjoy advanced features for seamless
                travel, all at your fingertips. Elevate your journey with BLUS –
                your gateway to luxury transportation.
                <br />
                <br />
                <span className="font-bold">
                  Text “BLUS” at (272) 223-7424 to receive an app link.
                </span>
              </p>
              {/* <div className="flex mb-8">
                <ImWhatsapp size={36} className="text-customBlack mr-2" />
                <p className="text-xs font-tavi text-customGold">
                  Call for book an order: <br />
                  <span className="text-lg text-customBlack">
                    (272) 223-7424
                  </span>
                </p>
              </div> */}
              <p className="font-tavi text-customBlack text-lg mb-4">
                Download our Appllication to track your order
              </p>
              <div className="flex">
                <img
                  src={ImageUrl("play-store.png")}
                  alt="play-store"
                  className="mr-8 max-w-[150px] max-sm:max-w-[100px]"
                />
                <img
                  src={ImageUrl("app-store.png")}
                  alt="app-store"
                  className="max-w-[150px] max-sm:max-w-[100px]"
                />
              </div>
            </div>
          </Col>
          <Col md={12} sm={18} className="self-center">
            <img src={ImageUrl("iPhone.png")} alt="mobile" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeComponent4;
