import { Col, Row } from "antd";
import { ImageUrl } from "../../../config/helper";

const AboutComponent2 = () => {
  return (
    <>
      <Row justify="center" className="py-16 max-sm:py-8">
        <Col md={21} xs={22}>
          <Row justify="center" gutter={[32, 0]}>
            <Col lg={14} xs={24}>
              <div className="flex flex-col items-start justify-center">
                <h3 className="font-tavi text-5xl text-customBlack capitalize mb-8 max-sm:mb-4 max-md:text-2xl">
                  Why We <span className="text-customGold">Started</span>
                </h3>
                <p className="font-euclid text-sm text-[#333333] leading-loose max-desktop:leading-relaxed">
                  In the heart of Houston, amidst the diverse tapestry of
                  cultures and dreams, two brothers, Shivam and Div Patel,
                  embarked on a journey that would not only redefine luxury
                  transportation but also pay homage to their heritage and
                  beloved late grandfather.
                  <br />
                  <br />
                  Born to immigrant parents who instilled in them the values of
                  hard work and determination, Shivam and Div were driven by a
                  shared passion for innovation and a deep-rooted desire to make
                  a positive impact in their adopted home. Armed with degrees in
                  computer science and a specialization in data analytics from
                  the University of Houston Downtown, the brothers were poised
                  to conquer new frontiers.
                  <br />
                  <br />
                  As they navigated the bustling streets of Houston, the
                  brothers observed the challenges faced by commuters,
                  particularly in accessing reliable, secure transportation.
                  Inspired by their late grandfather, whose regal presence and
                  commanding personality had left an indelible mark on their
                  lives, Shivam and Div envisioned a transportation service that
                  would embody his spirit of nobility and integrity. <br />
                  <br />
                  Thus, Badshah Limo was born, named in honor of their
                  grandfather, whose legacy of strength and dignity would be
                  carried forward through their enterprise. With a focus on
                  marrying cutting-edge technology with unparalleled service,
                  Shivam and Div set out to create a transportation experience
                  that would not only elevate the standards of luxury but also
                  prioritize safety and security above all else. <br />
                  <br /> Drawing upon their expertise in data analytics, the
                  brothers meticulously crafted algorithms to optimize route
                  planning, ensure prompt arrivals, and personalize the
                  passenger experience. But it wasn't just about efficiency; it
                  was about creating a sense of belonging, a feeling of being
                  cared for and protected, much like the presence of their
                  grandfather had once provided.
                  <br />
                  <br /> As Badshah Limo / BLUS took to the streets of Houston,
                  it quickly became more than just a transportation service; it
                  became a symbol of resilience, ingenuity, and the enduring
                  spirit of the immigrant dream. Through their dedication and
                  determination, Shivam and Div Patel had not only honored their
                  grandfather's memory but had also woven themselves into the
                  fabric of their beloved city, offering a beacon of hope and
                  possibility for all who called Houston home.
                </p>
              </div>
            </Col>
            <Col
              lg={10}
              xs={24}
              className="flex flex-col items-start max-lg:justify-center max-lg:mt-6"
            >
              <img
                src={ImageUrl("about-1.png")}
                alt="about-1"
                className="self-center"
              />
            </Col>
          </Row>

          <Row justify="center" className="my-16 max-desktop:my-8">
            <Col
              md={24}
              sm={24}
              className="py-32 max-desktop:py-16 border-y border-gray-300"
            >
              <Row
                justify="center"
                gutter={[32, 32]}
                className="md:px-32 md:h-full"
              >
                <Col lg={12} xs={24}>
                  <div className="flex flex-col justify-center items-center rounded-lg customShadow bg-white py-12 px-8 md:h-full aboutCard hover:border-4 hover:border-customGold">
                    <h5 className="text-customBlack text-3xl font-tavi text-center mb-8">
                      Mission
                    </h5>
                    <p className="font-euclid text-sm text-[#333333] leading-loose text-center">
                      Badshah Limo / BLUS redefines luxury transportation with a
                      seamless fusion of opulence and unparalleled safety,
                      ensuring passengers experience trust, comfort, and peace
                      of mind. Through innovation, dedication, and steadfast
                      values, we elevate transportation standards, becoming the
                      premier choice for discerning travelers in Houston and
                      beyond.
                    </p>
                  </div>
                </Col>
                <Col lg={12} xs={24}>
                  <div className="flex flex-col justify-center items-center rounded-lg customShadow bg-white py-12 px-8 md:h-full aboutCard hover:border-4 hover:border-customGold">
                    <h5 className="text-customBlack text-3xl font-tavi text-center mb-8">
                      Vision
                    </h5>
                    <p className="font-euclid text-sm text-[#333333] leading-loose text-center">
                      Badshah Limo /BLUS sets the standard for excellence in
                      transportation, instilling confidence, and peace of mind
                      in every journey. We aspire to be the trusted partner,
                      offering luxurious rides and a sanctuary of safety and
                      reliability. Leveraging technology and continuous
                      improvement, we envision Badshah Limo synonymous with the
                      highest standards of quality, comfort, and security,
                      enriching lives and communities.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="center" gutter={[32, 0]}>
            <Col lg={10} xs={24} className="self-center flex justify-center">
              <img
                src={ImageUrl("about-2.png")}
                alt="about-2"
                className="self-center"
              />
            </Col>
            <Col lg={14} xs={24}>
              <div className="flex flex-col items-start justify-center">
                <h2 className="font-tavi text-3xl text-customBlack capitalize my-8 max-sm:mb-4 max-md:text-lg">
                  Supporting Chauffeurs:{" "}
                  <span className="text-customGold">
                    Transparent Commission Distribution
                  </span>
                </h2>
                <p className="font-euclid text-sm text-[#333333] leading-loose">
                  At Badshah Limo, we believe in transparency and fairness for
                  all our chauffeurs. Unlike other ride-hailing apps like Uber
                  and Lyft, our commission distribution system is
                  straightforward and equitable. We prioritize the well-being
                  and satisfaction of our chauffeurs, recognizing their hard
                  work and dedication in providing premium transportation
                  services.
                  <br />
                  <br /> Our commission distribution model ensures that
                  chauffeurs receive their fair share of earnings for every ride
                  they complete. We provide detailed breakdowns of earnings,
                  clearly outlining how commissions are calculated, leaving no
                  room for ambiguity or dissatisfaction.
                  <br />
                  <br />
                  By fostering a culture of transparency and fairness, we not
                  only empower our chauffeurs but also strengthen our commitment
                  to providing the highest quality service to our passengers. At
                  Badshah Limo, we believe that when our chauffeurs thrive, so
                  do we, and that's why we're proud to offer a support system
                  that values integrity and transparency above all else.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row justify="center">
        <Col md={22} sm={22} className="py-32 border-y border-gray-300">
          <Row
            justify="center"
            gutter={[32, 32]}
            className="md:px-32 md:h-full"
          >
            <Col lg={12} md={22} sm={22} xs={22}>
              <div className="flex flex-col justify-center items-center rounded-lg shadow-xl bg-white py-12 px-8 md:h-full">
                <h5 className="text-customBlack text-3xl font-tavi text-center mb-8">
                  Mission
                </h5>
                <p className="font-euclid text-sm text-[#333333] leading-loose text-center max-md:text-xs">
                  Badshah Limo / BLUS redefines luxury transportation with a
                  seamless fusion of opulence and unparalleled safety, ensuring
                  passengers experience trust, comfort, and peace of mind.
                  Through innovation, dedication, and steadfast values, we
                  elevate transportation standards, becoming the premier choice
                  for discerning travelers in Houston and beyond.
                </p>
              </div>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} className="h-full">
              <div className="flex flex-col justify-center items-center rounded-lg shadow-xl bg-white py-12 px-8 md:h-full">
                <h5 className="text-customBlack text-3xl font-tavi text-center mb-8">
                  Vision
                </h5>
                <p className="font-euclid text-sm text-[#333333] leading-loose text-center max-md:text-xs">
                  Badshah Limo /BLUS sets the standard for excellence in
                  transportation, instilling confidence, and peace of mind in
                  every journey. We aspire to be the trusted partner, offering
                  luxurious rides and a sanctuary of safety and reliability.
                  Leveraging technology and continuous improvement, we envision
                  Badshah Limo synonymous with the highest standards of quality,
                  comfort, and security, enriching lives and communities.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </>
  );
};

export default AboutComponent2;
