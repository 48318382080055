import { Divider, Modal } from "antd";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/helper";
import { FaCheckCircle } from "react-icons/fa";
import { useState } from "react";

const PaymentModal = ({ paymentModal = false, setPaymentModal }) => {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("DISCOVER");
  const handleClick = () => {
    setPaymentModal(false);
  };
  return (
    <div>
      <Modal
        centered
        open={paymentModal}
        footer={null}
        onCancel={() => setPaymentModal(false)}
      >
        <h3 className="font-tavi text-2xl text-black max-sm:text-xl text-center my-4">
          Personal Ride
        </h3>
        <Divider />
        <h5 className="sm:px-4 font-tavi text-black text-xl max-sm:text-lg my-4">
          Payment Method
        </h5>
        <div
          className="flex justify-between items-center my-4 sm:px-4 cursor-pointer"
          onClick={() => setPaymentMethod("DISCOVER")}
        >
          <div className="flex justify-center items-center">
            <img src={ImageUrl("discover.png")} alt="card" />
            <p className="font-euclid text-sm font-semibold text-orange-500 ml-2 max-sm:text-xs">
              Discover 6723
            </p>
          </div>
          {paymentMethod === "DISCOVER" && (
            <FaCheckCircle size={24} className="text-green-500" />
          )}
        </div>
        <Divider />
        <div
          className="flex justify-between items-center my-4 sm:px-4 cursor-pointer"
          onClick={() => setPaymentMethod("AMERICAN")}
        >
          <div className="flex justify-center items-center">
            <img
              src={ImageUrl("express.png")}
              alt="card"
              className="border border-blue-800"
            />
            <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
              American Express 2008
            </p>
          </div>
          {paymentMethod === "AMERICAN" && (
            <FaCheckCircle size={24} className="text-green-500" />
          )}
        </div>
        <Divider />
        <div
          className="flex justify-between items-center my-4 sm:px-4 cursor-pointer"
          onClick={() => setPaymentMethod("VISA")}
        >
          <div className="flex justify-center items-center">
            <img
              src={ImageUrl("visa.png")}
              alt="card"
              className="border border-black"
            />
            <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
              Visa 9106
            </p>
          </div>
          {paymentMethod === "VISA" && (
            <FaCheckCircle size={24} className="text-green-500" />
          )}
        </div>
        <Divider />
        <div
          className="flex justify-between items-center my-4 sm:px-4 cursor-pointer"
          onClick={() => setPaymentMethod("APPLE")}
        >
          <div className="flex justify-center items-center">
            <img
              src={ImageUrl("apple.png")}
              alt="card"
              className="border border-black"
            />
            <p className="font-euclid text-sm font-semibold text-customBlack ml-2 max-sm:text-xs">
              Apply Pay
            </p>
          </div>
          {paymentMethod === "APPLE" && (
            <FaCheckCircle size={24} className="text-green-500" />
          )}
        </div>
        <button
          className="uppercase bg-button py-4  text-white rounded-lg text-sm font-bold max-sm:text-xs  mt-6 w-full"
          onClick={handleClick}
        >
          Save
        </button>
      </Modal>
    </div>
  );
};

export default PaymentModal;
